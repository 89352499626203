import React, { useEffect, useState } from "react"

import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { alertWarningError } from "../../components/Notificaciones"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import Autocomplete from "@material-ui/lab/Autocomplete"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import BackdropCustom from "../../components/BackdropCustom"
import CircularProgress from "@material-ui/core/CircularProgress"

import { marker } from "leaflet"

const inicialValue = {
  name: "",
  description: "",
  market: 0,
  whatsapp: "",
  instagram: "",
  facebook: "",
  phoneNumber: "",
  fromDate: "",
  toDate: "",
  price: "",
  category: null,
  image: null,
}

export default function NuevaOferta() {
  const history = useHistory()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [oferta, setOferta] = useState(inicialValue)
  const [fotoPerfil, setFotoPerfil] = useState({})
  const [categoria, setCategoria] = useState({})
  const [rawImage, setRawImage] = useState({})
  const [isLoadingMercado, setIsLoadingMercado] = useState(false)

  const [mercadoList, setMercadoList] = useState([])
  const [mercado, setMercado] = useState()

  useEffect(() => {
    getData()
  }, [])

  const getData = async (props) => {
    setIsLoadingMercado(true)
    let url = "/offers/get-all-markets"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const mercado = response.data

        setMercadoList(mercado)

        setIsLoadingMercado(false)
      }
    } catch (error) {
      setIsLoadingMercado(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let fileResponse = null
    if (Object.keys(fotoPerfil).length !== 0) {
      const formData = new FormData()
      formData.append("file", rawImage, rawImage.name)
      fileResponse = await axios.post("/files/upload-image/oferta", formData)
    }
    try {
      const response = await axios.post("offers", {
        ...oferta,
        image: fileResponse?.data,
        market: mercado?.id,
      })
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        await swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        // location.reload()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const handleFileChange = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]
    setRawImage(file)
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = function () {
      let base64 = reader.result.split(";base64,")
      let documentoBase64 = base64[1]
      let imagen = {
        file: file,
        type: file.type,
        name: file.name,
        data: documentoBase64,
      }
      setFotoPerfil(imagen)
    }
    setIsLoading(false)
    reader.onerror = function () {
      alert("No se pudo leer el archivo")
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...oferta, name: event.target.value }
    setOferta(copyInput)
  }

  // const handleChangeMarket = (event) => {
  //   let copyInput = { ...oferta, market: event.target.value }
  //   setOferta(copyInput)
  // }

  const handleChangeDescripcion = (event) => {
    let copyInput = { ...oferta, description: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeCosto = (event) => {
    let copyInput = { ...oferta, price: Number(event.target.value) }
    setOferta(copyInput)
  }

  // const handleChangeUrlSitio = (event) => {
  //   let copyInput = { ...oferta, urlSitio: event.target.value }
  //   setOferta(copyInput)
  // }

  const handleChangeFacebook = (event) => {
    let copyInput = { ...oferta, facebook: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeInstagram = (event) => {
    let copyInput = { ...oferta, instagram: event.target.value }
    setOferta(copyInput)
  }

  // const handleChangeOtrasRedes = (event) => {
  //   let copyInput = { ...oferta, otrasRedes: event.target.value }
  //   setOferta(copyInput)
  // }

  const handleChangeNumeroContacto = (event) => {
    let copyInput = { ...oferta, phoneNumber: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeWhatsapp = (event) => {
    let copyInput = { ...oferta, whatsapp: event.target.value }
    setOferta(copyInput)
  }

  // const handleChangeLatitud = (event) => {
  //   let copyInput = { ...oferta, latitud: event.target.value }
  //   setOferta(copyInput)
  // }
  //
  // const handleChangeLongitud = (event) => {
  //   let copyInput = { ...oferta, longitud: event.target.value }
  //   setOferta(copyInput)
  // }
  const handleChangeFechaDesde = (event) => {
    let copyInput = { ...oferta, fromDate: event.target.value }
    setOferta(copyInput)
  }

  const handleChangeFechaHasta = (event) => {
    let copyInput = { ...oferta, toDate: event.target.value }
    setOferta(copyInput)
  }
  const handleEliminarImagen = (event) => {
    event.preventDefault()
    setFotoPerfil({})
  }

  const onSelectCategoria = (e, value) => {
    let copyInput = { ...oferta, category: value }
    setOferta(copyInput)
  }

  const onSelectMercado = (e, value) => {
    if (value && value?.id !== mercado?.id) {
      setMercado(value)
      let copyInput = { ...oferta, id: value?.id }
      setOferta(copyInput)
    }

    if (value === null) {
      setMercado({})
      let copyInput = { ...oferta, id: 0 }
      setOferta(copyInput)
    }
  }
  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              {Object.keys(fotoPerfil).length === 0 ? (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Avatar alt="Foto Perfil" style={{ width: 100, height: 100 }}>
                    <PhotoCameraIcon fontSize="large" />
                  </Avatar>
                </Box>
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => handleEliminarImagen(event)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      alt="Foto Perfil"
                      src={`data:${fotoPerfil?.type};base64,${fotoPerfil?.data}`}
                      style={{ width: 140, height: 140 }}
                    />
                  </Badge>
                </Box>
              )}

              <input
                type="file"
                accept=".png, .jpeg, .jpg"
                style={{ display: "none" }}
                id="upload-input"
                onChange={(event) => handleFileChange(event)}
              />
              <Button
                size="small"
                //  startIcon={<PhotoCameraIcon />}
                //startIcon={PublishIcon}
                variant="contained"
                color="primary"
                children="Foto de Oferta"
                onClick={() => document.getElementById("upload-input").click()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={9} lg={9} xl={9}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={5} lg={5} xl={5}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={oferta.name}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={7} lg={7} xl={7}>
                  <TextField
                    size="small"
                    value={oferta.description}
                    onChange={(value) => handleChangeDescripcion(value)}
                    variant="outlined"
                    id="descripcion"
                    name="descripcion"
                    label="Descripción "
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <Autocomplete
                    id="market"
                    size="small"
                    value={mercado || ""}
                    onChange={onSelectMercado}
                    options={mercadoList}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option?.name}</React.Fragment>
                    )}
                    loading={isLoadingMercado}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Mercado"
                        name="market"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingMercado ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.whatsapp}
                    onChange={(value) => handleChangeWhatsapp(value)}
                    variant="outlined"
                    id="Whatsapp"
                    name="Whatsapp"
                    label="Whatsapp"
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.phoneNumber}
                    onChange={(value) => handleChangeNumeroContacto(value)}
                    variant="outlined"
                    id="telefono"
                    name="telefono"
                    label="Teléfono"
                    type="number"
                    fullWidth
                  />
                </Grid>
                {/*<Grid item sm={12} md={4} lg={4} xl={4}>*/}
                {/*  <TextField*/}
                {/*    size="small"*/}
                {/*    value={oferta.urlSitio}*/}
                {/*    onChange={(value) => handleChangeUrlSitio(value)}*/}
                {/*    variant="outlined"*/}
                {/*    id="urlSitio"*/}
                {/*    name="urlSitio"*/}
                {/*    label="URL Sitio"*/}
                {/*    type="text"*/}
                {/*    fullWidth*/}
                {/*  />*/}
                {/*</Grid>*/}

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.facebook}
                    onChange={(value) => handleChangeFacebook(value)}
                    variant="outlined"
                    id="facebook"
                    name="facebook"
                    label="Facebook"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={oferta.instagram}
                    onChange={(value) => handleChangeInstagram(value)}
                    variant="outlined"
                    id="instagram"
                    name="instagram"
                    label="Instagram"
                    type="text"
                    fullWidth
                  />
                </Grid>

                {/*<Grid item sm={12} md={4} lg={4} xl={4}>*/}
                {/*  <TextField*/}
                {/*    size="small"*/}
                {/*    value={oferta.otrasRedes}*/}
                {/*    onChange={(value) => handleChangeOtrasRedes(value)}*/}
                {/*    variant="outlined"*/}
                {/*    id="otrasRedes"*/}
                {/*    name="otrasRedes"*/}
                {/*    label="Otras Redes"*/}
                {/*    type="text"*/}
                {/*    fullWidth*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item sm={12} md={4} lg={4} xl={4}>*/}
                {/*  <TextField*/}
                {/*    size="small"*/}
                {/*    value={oferta.latitud}*/}
                {/*    onChange={(value) => handleChangeLatitud(value)}*/}
                {/*    variant="outlined"*/}
                {/*    id="Latitud"*/}
                {/*    name="Latitud"*/}
                {/*    label="Latitud"*/}
                {/*    type="number"*/}
                {/*    fullWidth*/}
                {/*  />*/}
                {/*</Grid>*/}

                {/*<Grid item sm={12} md={4} lg={4} xl={4}>*/}
                {/*  <TextField*/}
                {/*    size="small"*/}
                {/*    value={oferta.longitud}*/}
                {/*    onChange={(value) => handleChangeLongitud(value)}*/}
                {/*    variant="outlined"*/}
                {/*    id="Longitud"*/}
                {/*    name="Longitud"*/}
                {/*    label="Longitud"*/}
                {/*    type="number"*/}
                {/*    fullWidth*/}
                {/*  />*/}
                {/*</Grid>*/}
                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <TextField
                    size="small"
                    value={oferta.fromDate}
                    onChange={(value) => handleChangeFechaDesde(value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="desde"
                    name="desde"
                    label="Fecha desde"
                    type="date"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <TextField
                    size="small"
                    value={oferta.toDate}
                    onChange={(value) => handleChangeFechaHasta(value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="hasta"
                    name="hasta"
                    label="Fecha hasta"
                    type="date"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <Autocomplete
                    size="small"
                    value={oferta.category || ""}
                    onChange={onSelectCategoria}
                    options={[
                      "CARNES",
                      "PANIFICADOS",
                      "ACEITES",
                      "ENLATADOS",
                      "SALSAS",
                      "FRUTAS",
                      "VERDURAS",
                      "EMBUTIDOS",
                      "OTROS",
                    ]}
                    getOptionLabel={(option) => (option ? option : "")}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Categoría"
                        name="Categoría"
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <TextField
                    size="small"
                    value={oferta.price}
                    onChange={(value) => handleChangeCosto(value)}
                    variant="outlined"
                    id="precio"
                    name="precio"
                    label="Precio"
                    type="number"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
