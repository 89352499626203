import React, { useState, useEffect } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import BackdropCustom from "../../components/BackdropCustom"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import swal from "sweetalert"

const inicialValue = {
  id: 0,
  nombre: "",
  descripcion: "",
  esAdmin: false,
}

export default function EditarTipoColaborador() {
  const history = useHistory()
  const classes = useStyles()
  const dataProps = history.location.state
  const [isLoading, setIsLoading] = useState(false)
  const [tipoColaborador, setTipoColaborador] = useState(inicialValue)

  useEffect(() => {
    if (dataProps?.id_tp_usuario_crm > 0) {
      let copyInput = {
        ...tipoColaborador,
        id: dataProps?.id_tp_usuario_crm,
        nombre: dataProps?.nombre,
        descripcion: dataProps?.descripcion,
        esAdmin: dataProps?.es_admin,
      }
      setTipoColaborador(copyInput)
    }
  }, [])

  const handleChangeNombre = (event) => {
    let copyInput = { ...tipoColaborador, nombre: event.target.value }
    setTipoColaborador(copyInput)
  }

  const handleChangeDescripcion = (event) => {
    let copyInput = { ...tipoColaborador, descripcion: event.target.value }
    setTipoColaborador(copyInput)
  }

  const handleChangeEsAdmin = (event) => {
    let copyInput = { ...tipoColaborador, esAdmin: event.target.checked }
    setTipoColaborador(copyInput)
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "colaboradores/tipo-crear"
    try {
      const response = await axios.post(url, tipoColaborador)
      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  return (
    <>
      <BackdropCustom open={isLoading} />
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid item sm={6} md={4} lg={4} xl={4}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="nombre"
                name="nombre"
                label="Nombre del tipo colaborador"
                type="text"
                value={tipoColaborador.nombre}
                onChange={(value) => handleChangeNombre(value)}
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6} xl={6}>
              <TextField
                size="small"
                variant="outlined"
                id="descripciop"
                name="descripciop"
                label="Descripción"
                type="text"
                value={tipoColaborador.descripcion}
                onChange={(value) => handleChangeDescripcion(value)}
                className={classes.DialogoTexto}
                fullWidth
              />
            </Grid>

            <Grid item sm={12} md={2} lg={2} xl={2}>
              <FormGroup row>
                <FormControlLabel
                  className={classes.DialogoTexto}
                  control={
                    <Checkbox
                      checked={tipoColaborador.esAdmin}
                      onChange={(value) => handleChangeEsAdmin(value)}
                      name="esAdmin"
                      color="primary"
                    />
                  }
                  label="Administrador"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={tipoColaborador.nombre === "" ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
