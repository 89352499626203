import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import {
  Typography,
  Chip,
  Modal,
  Box,
  Avatar,
  Button,
  Paper,
} from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import AddIcon from "@material-ui/icons/AddCircle"
import { pink } from "@material-ui/core/colors"
import { PlaceOutlined } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { NoteAlt, NoteAltOutlined } from "@mui/icons-material"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import swal from "sweetalert"
import { render } from "@testing-library/react"
const useModalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "8px",
  },
  closeButton: {
    marginTop: theme.spacing(2),
  },
  jsonBox: {
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(1),
    borderRadius: "4px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    whiteSpace: "pre-wrap",
    fontFamily: "monospace",
  },
}))

const pointDTO = {
  name: "",
  latLong: null,
  interestFamily: null,
  interestType: null,
  interestSubType: null,
  data: {},
  punteroUser: null,
  comments: "",
  mainAddress: "",
  secondaryAddress: "",
  department: "",
  neighborhood: "",
  city: "",
  houseNumber: null,
  status: "",
  id: "",
  facadePicture: null,
  roadPicture: null,
  singPicture: null,
  additionalPicture: null,
  fechaDeActualizacion: "",
}

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0") // Los meses son indexados desde 0
  const year = String(date.getFullYear()).slice(-2) // Obtener los últimos dos dígitos del año
  return `${day}/${month}/${year}`
}
export default function ListarPuntoDeInteres() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const modalClasses = useModalStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [puntodeInteres, setPuntodeInteres] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showModalImgCalle, setShowModalImgCalle] = useState(false)
  const [showModalImgCartel, setShowModalImgCartel] = useState(false)
  const [showModalImgPort, setShowModalImgPort] = useState(false)
  const [showModalImgAdicional, setShowModalImgAdicional] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState("PENDIENTE")
  const [openStantusChangerDialog, setOpenStantusChangerDialog] = useState(false)
  const [pointToChangeHisStatus, setPointToChangeHisStatus] = useState(pointDTO)
  const [status] = useState({
    content: [
      { status: "ACEPTADO" },
      { status: "PENDIENTE" },
      { status: "RECHAZADO" },
      { status: "BORRADOR" },
    ],
  })

  useEffect(() => {
    getPuntosDeInteres()
  }, [])

  const dataSetterFunction = (props) => {
    setOpenStantusChangerDialog(true)
    setPointToChangeHisStatus({
      ...pointToChangeHisStatus,
      id: props.id,
      name: props.name,
      latLong: props.latLong,
      interestFamily: props.interestFamily,
      interestType: props.interestType,
      interestSubType: props.interestSubType,
      data: props.data,
      punteroUser: props.punteroUser,
      comentarios: props.comments,
      mainAddress: props.mainAddress,
      secondaryAddress: props.secondaryAddress,
      department: props.department,
      neighborhood: props.neighborhood,
      city: props.city,
      houseNumber: props.houseNumber,
      status: props.status,
      facadePicture: props.facadePicture,
      roadPicture: props.roadPicture,
      singPicture: props.singPicture,
      additionalPicture: props.additionalPicture,
      fechaDeActualizacion: formateadorSuperProDeFechas(props.updatedAt),
    })
  }

  const changeStatus = async () => {
    try {
      const response = await axios.patch(
        `interest/update-point-of-interest/${pointToChangeHisStatus.data.id}`,
        { ...pointToChangeHisStatus.data, status: selectedStatus },
      )
      if (response.status === 200) {
        await swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        await getPuntosDeInteres()
      }
    } catch (error) {
      if (error.response) {
        await alertWarningError(error.response)
      }
    } finally {
      setOpenStantusChangerDialog(false)
    }
  }

  const formateadorSuperProDeFechas = (dateString) => {
    const date = new Date(dateString)
    const day = String(date.getUTCDate()).padStart(2, "0")
    const month = String(date.getUTCMonth() + 1).padStart(2, "0")
    const year = date.getUTCFullYear()
    return `${day}/${month}/${year}`
  }

  const getPuntosDeInteres = async () => {
    const url = "interest/all-point-of-interest"
    try {
      const responsePuntoInteres = await axios.get(url)
      const responseStatus = responsePuntoInteres.status
      if (responseStatus === 200) {
        const formattedData = responsePuntoInteres.data.map((item) => ({
          ...item,
          fecha: formatDate(item.fecha),
        }))
        setPuntodeInteres(formattedData)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }
  const title = (
    <>
      {/*<Button*/}
      {/*  size="small"*/}
      {/*  variant="outlined"*/}
      {/*  style={{ color: pink[600] }}*/}
      {/*  startIcon={<AddIcon />}*/}
      {/*  onClick={() => history.push("/nueva-familia/")}*/}
      {/*>*/}
      {/*  Agregar*/}
      {/*</Button>*/}

      <Button
        size="small"
        variant="outlined"
        style={{ color: "Primary", marginLeft: 10 }}
        startIcon={<PlaceOutlined />}
        onClick={() => history.push("/getAll-Punto-Interes-maps/")}
      >
        Ver todos en el mapa
      </Button>
    </>
  )
  const columns = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true,
    },
    {
      title: "Nombre",
      field: "name",
      width: "20%",
    },
    {
      title: "Familia",
      width: "20%",
      render: (rowData) => <p>{rowData.interestFamily?.name}</p>,
    },
    {
      title: "Tipo",
      width: "20%",
      render: (rowData) => <p>{rowData.interestType?.subCategory}</p>,
    },
    {
      title: "SubTipo",
      width: "20%",
      render: (rowData) => <p>{rowData.interestSubType?.serviceTarget}</p>,
    },
    {
      title: "Estado",
      field: "status",
      width: "20%",
    },
    {
      title: "Fecha",
      field: "fecha",
      width: "20%",
      render: (rowData) => <p>{formatDate(rowData.updatedAt)}</p>,
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }
  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]
  const handleViewMaps = (latLong) => {
    const { lat, long } = latLong
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`
    window.open(url, "_blank")
  }
  const changeStatusSelector = (e, status) => {
    setSelectedStatus(status.status)
    setPointToChangeHisStatus({
      ...pointToChangeHisStatus,
      status: status.status,
    })
  }
  const closeStatusDialog = () => {
    setOpenStantusChangerDialog(false)
    setPointToChangeHisStatus(pointDTO)
  }
  const handleOpen = (data) => {
    setSelectedData(data)
    setShowModal(true)
  }
  const handleClose = () => {
    setShowModal(false)
    setSelectedData(null)
  }

  const handleOpenImgCalle = () => {
    setShowModalImgCalle(true)
  }

  const handleCloseImgCalle = () => {
    setShowModalImgCalle(false)
  }

  const handleOpenImgCartel = () => {
    setShowModalImgCartel(true)
  }

  const handlecloseImgCartel = () => {
    setShowModalImgCartel(false)
  }

  const handleOpenPort = () => {
    setShowModalImgPort(true)
  }

  const handleClosePort = () => {
    setShowModalImgPort(false)
  }

  const handleOpenImgAdicional = () => {
    setShowModalImgAdicional(true)
  }

  const handlecloseImgAdicional = () => {
    setShowModalImgAdicional(false)
  }

  const childrenAccions = (props) => {
    return (
      <>
        <Chip
          onClick={() => handleOpen(props.data)}
          avatar={
            <Avatar variant="circular" sx={{ bgcolor: "red" }}>
              <VisibilityIcon fontSize="small" style={{ color: "#ffffff" }} />
            </Avatar>
          }
          label="Ver"
          variant="outlined"
          color="secondary"
          style={{ marginLeft: 5, marginRight: 5 }}
        />
        <Chip
          onClick={() => history.push("/editar-punto-de-interes/", props.data)}
          avatar={
            <Avatar variant="circular" sx={{ bgcolor: "red" }}>
              <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
            </Avatar>
          }
          label="Editar"
          variant="outlined"
          color="primary"
          style={{ marginRight: 5 }}
        />

        <Chip
          onClick={() => dataSetterFunction(props)}
          avatar={
            <Avatar variant="circular" sx={{ bgcolor: "green" }}>
              <NoteAltOutlined fontSize="small" style={{ color: "#008000" }} />
            </Avatar>
          }
          label="Estado"
          variant="outlined"
          style={{ color: "green", borderColor: "green" }}
        />

        <Modal
          style={{
            height: 1000,
            borderRadius: "5%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className={modalClasses.modal}
          open={openStantusChangerDialog}
          onClose={() => setOpenStantusChangerDialog(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper className={classes.paper} style={{ padding: 50 }}>
            <Typography
              variant="h6"
              id="simple-modal-title"
              style={{ marginBottom: 20 }}
            >
              Cambiar estado del punto de interes
            </Typography>
            <div className={classes.jsonBox}>
              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ marginBottom: 10 }}
              >
                <Autocomplete
                  id="Estado"
                  size="small"
                  onChange={changeStatusSelector}
                  options={status.content}
                  value={
                    status.content.find(
                      (obj) => obj.status === pointToChangeHisStatus.status,
                    ) || ""
                  }
                  getOptionLabel={(option) => option.status ?? ""}
                  renderOption={(option) => (
                    <React.Fragment>{option.status}</React.Fragment>
                  )}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Estado"
                      name="estado"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={closeStatusDialog}
                  className={modalClasses.closeButton}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changeStatus}
                  className={modalClasses.closeButton}
                >
                  Guardar
                </Button>
              </div>
            </div>
          </Paper>
        </Modal>
      </>
    )
  }
  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={puntodeInteres}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              childrenAccions,
            }}
          />
          {selectedData && (
            <Modal
              open={showModal}
              onClose={handleClose}
              className={modalClasses.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ width: "100%" }}
            >
              <Paper className={modalClasses.paper}>
                <Typography variant="h6" id="simple-modal-title">
                  {selectedData.nombre_del_punto}
                </Typography>
                <div className={modalClasses.jsonBox}>
                  <Typography variant="body2">
                    <strong> Ciudad:</strong> {selectedData.city}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Barrio:</strong> {selectedData.neighborhood}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Calle 1:</strong> {selectedData.mainAddress}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Calle 2:</strong> {selectedData.secondaryAddress}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Nro de Casa:</strong> {selectedData.houseNumber}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Estado:</strong> {selectedData.status}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Familia:</strong> {selectedData.interestFamily?.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Tipo:</strong> {selectedData.interestType?.subCategory}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Sub Tipo:</strong>{" "}
                    {selectedData.interestSubType?.serviceTarget}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Fecha de actualizacion:</strong>{" "}
                    {formatDate(selectedData.updatedAt)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Comentarios:</strong> {selectedData.comments}
                  </Typography>
                </div>
                <div className={modalClasses.jsonBox}>
                  <Typography variant="body2">
                    <strong>Datos:</strong>
                  </Typography>
                  <div>
                    {Object.keys(selectedData.data).map((item, index) => (
                      <div key={index}>
                        <p
                          style={{
                            fontWeight: "bold",
                            marginBottom: 1,
                            textUnderlinePosition: "under",
                            textUnderlineOffset: 1,
                          }}
                        >
                          {item}
                        </p>
                        {Object.keys(selectedData.data[item]).map((val, pos) => (
                          <p key={pos}>
                            {val}: {selectedData.data[item][val]}
                          </p>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>

                <div className={modalClasses.jsonBox}>
                  <Typography variant="body2">
                    <strong>Ubicacion :</strong>
                  </Typography>
                  <Chip
                    onClick={() => handleViewMaps(selectedData.latLong)}
                    avatar={
                      <Avatar variant="circular" sx={{ bgcolor: "red" }}>
                        <PlaceOutlined
                          fontSize="small"
                          style={{ color: "#ffffff" }}
                        />
                      </Avatar>
                    }
                    label="Ver en el mapa"
                    variant="outlined"
                    color="primary"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 45,
                  }}
                >
                  {selectedData.facadePicture ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Foto Fachada
                      </Typography>
                      <img
                        onClick={() => handleOpenPort()}
                        style={{
                          height: 220,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                        src={selectedData.facadePicture?.url}
                        alt="Pendiente de Recarga"

                        // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
                      />
                      <Modal
                        style={{
                          height: 1000,
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                        open={showModalImgPort}
                        onClose={handleClosePort}
                      >
                        <img
                          style={{
                            height: 700,
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                          src={selectedData.facadePicture?.url}
                          alt="Pendiente de Recarga"

                          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
                        />
                      </Modal>
                    </div>
                  ) : (
                    <Typography style={{ fontWeight: "bold" }}>
                      No existe Foto fachada
                    </Typography>
                  )}

                  {selectedData.roadPicture ? (
                    <div>
                      <Typography style={{ fontWeight: "bold" }}>
                        Foto calle
                      </Typography>

                      <img
                        onClick={() => handleOpenImgCalle()}
                        style={{
                          height: 220,
                          marginRight: 10,
                          marginLeft: 10,
                        }}
                        src={selectedData.roadPicture?.url}
                        alt="Pendiente de Recarga"

                        // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
                      />
                      <Modal
                        style={{
                          height: 1000,
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                        open={showModalImgCalle}
                        onClose={handleCloseImgCalle}
                      >
                        <img
                          style={{
                            height: 700,
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                          src={selectedData.roadPicture?.url}
                          alt="Pendiente de Recarga"

                          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
                        />
                      </Modal>
                    </div>
                  ) : (
                    <Typography style={{ fontWeight: "bold" }}>
                      No existe Foto calle
                    </Typography>
                  )}

                  {selectedData.additionalPicture ? (
                    <div>
                      <Typography
                        onClick={() => handleOpenImgAdicional()}
                        style={{ fontWeight: "bold" }}
                      >
                        Foto adicional
                      </Typography>
                      <div onClick={() => handleOpenImgAdicional()}>
                        <img
                          onClick={() => handleOpenImgAdicional()}
                          style={{ height: 220 }}
                          src={selectedData.additionalPicture?.url}
                          alt="Pendiente de Recarga"

                          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
                        />
                      </div>

                      <Modal
                        style={{
                          height: 1000,
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                        open={showModalImgAdicional}
                        onClose={handlecloseImgAdicional}
                      >
                        <img
                          style={{
                            height: 700,
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                          src={selectedData.additionalPicture?.url}
                          alt="Pendiente de Recarga"

                          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
                        />
                      </Modal>
                    </div>
                  ) : (
                    <Typography style={{ fontWeight: "bold" }}>
                      No existe Foto Adicional
                    </Typography>
                  )}
                </div>

                {selectedData.singPicture ? (
                  <div style={{ marginTop: 20 }}>
                    <Typography
                      onClick={() => handleOpenImgCartel()}
                      style={{ fontWeight: "bold" }}
                    >
                      Foto cartel
                    </Typography>
                    <div onClick={() => handleOpenImgCartel()}>
                      <img
                        onClick={() => handleOpenImgCartel()}
                        style={{ height: 220, width: "100%" }}
                        src={selectedData.singPicture?.url}
                        alt="Pendiente de Recarga"

                        // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
                      />
                    </div>

                    <Modal
                      style={{
                        height: 1000,
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                      open={showModalImgCartel}
                      onClose={handlecloseImgCartel}
                    >
                      <img
                        style={{
                          height: "auto",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          padding: 50,
                        }}
                        src={selectedData.singPicture?.url}
                        alt="Pendiente de Recarga"

                        // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
                      />
                    </Modal>
                  </div>
                ) : (
                  <Typography style={{ fontWeight: "bold", marginTop: 50 }}>
                    No existe Foto cartel
                  </Typography>
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  className={modalClasses.closeButton}
                >
                  Cerrar
                </Button>
              </Paper>
            </Modal>
          )}
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
