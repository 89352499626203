import { useHistory } from "react-router-dom"
import React, { useEffect, useState, useContext } from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { Grid, Typography } from "@material-ui/core"
import { useStyles } from "../../assets/styles/CustomStyles"
import axios from "../../utils/axios"
import Box from "@material-ui/core/Box"
import {
  alertaAceptarCancelar,
  alertWarningError,
} from "../../components/Notificaciones"
import TypographyBold from "../../components/TypographyBold"
import BackdropCustom from "../../components/BackdropCustom"
import Paper from "@material-ui/core/Paper"
import Localizacion from "../../components/MaterialTables/Localization"
import TableIcons from "../../components/MaterialTables/TableIcons"
import BlockIcon from "@material-ui/icons/Block"
import Chip from "@material-ui/core/Chip"
import CardHeader from "@material-ui/core/CardHeader"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import AssignmentIcon from "@material-ui/icons/Assignment"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import LinearProgress from "@material-ui/core/LinearProgress"
import Tooltip from "@material-ui/core/Tooltip"
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle"
import DeleteIcon from "@material-ui/icons/Delete"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "@material-ui/core/IconButton"
import swal from "sweetalert"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import SaveIcon from "@material-ui/icons/Save"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"

const DetalleCartera = () => {
  const history = useHistory()

  const dataProps = history.location.state

  const userContext = useContext(UserContext)
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [cartera, setCartera] = useState({})
  const [clientes, setClientes] = useState({ content: [] })
  const [carteraList, setCarteraList] = useState({ content: [] })
  const [open, setOpen] = useState(false)
  const [openLoad, setOpenLoad] = useState(false)
  const [isLoadingCartera, setIsLoadingCartera] = useState(false)
  const [cliente, setCliente] = useState({})

  useEffect(() => {
    if (dataProps?.idCartera > 0) {
      setCartera(dataProps)
      getClientes(dataProps?.idCartera)
      getCartera()
    }
  }, [])

  const getClientes = async (props) => {
    setIsLoading(true)
    let url = "carteras/comercio-listar"
    try {
      const response = await axios.post(url, {
        idCartera: props,
      })
      let status = response.status
      if (status === 200) {
        const cliente = response.data
        setClientes({ ...clientes, content: cliente?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getCartera = async (props) => {
    setIsLoadingCartera(true)
    let url = "carteras/cartera-listar"
    try {
      const response = await axios.post(url)
      let status = response.status
      if (status === 200) {
        const carteras = response.data
        setCarteraList({ ...carteraList, content: carteras?.result })

        setIsLoadingCartera(false)
      }
    } catch (error) {
      setIsLoadingCartera(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const eliminar = async (props) => {
    setIsLoading(true)
    let url = "carteras/comercio-eliminar"
    try {
      const response = await axios.post(url, {
        idComercio: props,
      })
      let status = response.status
      if (status === 200) {
        getClientes(cartera?.idCartera)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const eliminarCliente = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres eliminar a ${props?.comercio}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminar(props?.idComercio)
      }
    })
  }
  const moverCliente = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
    setOpen(true)
    setCliente(props)
  }
  const handleClose = () => {
    setOpen(false)
    setOpenLoad(false)
  }

  function AsignarCartera(props) {
    const { open, onClose, data } = props
    const [carteraSelect, setCarteraSelect] = useState({})

    const onSelectCartera = (e, value) => {
      if (value && value?.idCartera !== carteraSelect?.idCartera) {
        setCarteraSelect(value)
      }

      if (value === null) {
        setCarteraSelect({})
      }
    }

    function Loaders() {
      return <LinearProgress />
    }

    const handleGuardar = async () => {
      setIsLoading(true)
      onClose()
      let url = "carteras/comercio-mover"

      let newObject = {
        idCartera: carteraSelect?.idCartera,
        idComercio: data?.idComercio,
      }

      try {
        const response = await axios.post(url, newObject)
        let status = response.status
        if (status === 200) {
          setIsLoading(false)
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          getClientes(cartera?.idCartera)
        }
      } catch (error) {
        onClose()
        setIsLoading(false)
        if (error.response) {
          alertWarningError(error.response)
        }
      }
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          ASIGNAR CLIENTE A CARTERA
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                CLIENTE SELECCIONADO
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Chip
                  label={data?.nombre}
                  variant="default"
                  color="primary"
                  icon={<AssignmentIndIcon />}
                />
              </Box>
            </Grid>

            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                SELECCIONAR CARTERA
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Autocomplete
                  id="idCartera"
                  onChange={onSelectCartera}
                  size="small"
                  value={carteraSelect || ""}
                  loading={isLoadingCartera}
                  options={carteraList.content}
                  getOptionLabel={(option) => (option.cartera ? option.cartera : "")}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="cartera"
                      label="Cartera"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingCartera ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={Object.keys(cartera).length === 0 ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </DialogActions>
        {openLoad && <Loaders />}
      </Dialog>
    )
  }

  return (
    <>
      {userContext.state ? (
        <>
          <BackdropCustom open={isLoading} />

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 10 }}
          >
            <Card className={classes.root}>
              <CardHeader
                title={
                  <TypographyBold variant="body1">
                    {cartera?.cartera}
                    <Chip
                      label={"ID " + cartera?.idCartera}
                      variant="default"
                      color="primary"
                      size="small"
                      icon={<AssignmentIcon />}
                    />
                  </TypographyBold>
                }
              />
              <CardContent>
                <Box ml={1} mt={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <TypographyBold
                          variant="body2"
                          display="block"
                          color="textSecondary"
                        >
                          USUARIO ASIGNADO
                        </TypographyBold>
                        <Box ml={1} mt={1} mb={1}>
                          {cartera?.nombreUsuario !== "" ? (
                            <List dense style={{ maxWidth: "36ch" }}>
                              <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                  <Avatar
                                    alt={cartera?.nombreUsuario}
                                    src={cartera?.foto_usuario}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    cartera?.nombreUsuario +
                                    " " +
                                    cartera?.apellidoUsuario
                                  }
                                />
                              </ListItem>
                              <Divider variant="inset" component="li" />
                            </List>
                          ) : (
                            <Chip
                              label="NO CUENTAS CON USUARIO ASIGNADO"
                              variant="default"
                              color="primary"
                              size="small"
                              icon={<BlockIcon />}
                            />
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <TypographyBold
                          variant="body2"
                          display="block"
                          color="textSecondary"
                        >
                          CLIENTES DE LA CARTERA
                        </TypographyBold>
                        <Box ml={1} mb={1}>
                          <List dense>
                            {clientes.content.length > 0 ? (
                              clientes.content.map((value) => {
                                return (
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar
                                        alt={value?.comercio}
                                        src={value?.urlFoto}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={value?.comercio}
                                      secondary={"ID: " + value?.idCartera}
                                    />
                                    <ListItemSecondaryAction>
                                      <Tooltip
                                        title="Mover cliente a otra cartera"
                                        aria-label="mover"
                                      >
                                        <IconButton
                                          color="primary"
                                          edge="end"
                                          aria-label="mover"
                                          onClick={(event) =>
                                            moverCliente(event, value)
                                          }
                                        >
                                          <SwapHorizontalCircleIcon
                                            style={{ fontSize: 30 }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title="Eliminar cliente"
                                        aria-label="eleminar"
                                      >
                                        <IconButton
                                          edge="end"
                                          aria-label="delete"
                                          onClick={(event) =>
                                            eliminarCliente(event, value)
                                          }
                                        >
                                          <DeleteIcon style={{ fontSize: 30 }} />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              })
                            ) : (
                              <Chip
                                label="SIN CLIENTES EN CARTERA"
                                variant="default"
                                color="primary"
                                size="small"
                                icon={<BlockIcon />}
                              />
                            )}
                          </List>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item>
                    <BotonGris
                      size="small"
                      color="default"
                      startIcon={<ArrowBackIosIcon />}
                      onClick={() => {
                        history.goBack()
                      }}
                    >
                      Salir
                    </BotonGris>{" "}
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          {open && (
            <AsignarCartera open={open} onClose={handleClose} data={cliente} />
          )}
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}

export default DetalleCartera
