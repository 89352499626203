import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useTheme } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/core/styles"
import { MakeTables } from "../../components/MaterialTables/MakeTables"

import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"
import { useHistory } from "react-router-dom"

import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Button from "@material-ui/core/Button"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

import Alert from "@material-ui/lab/Alert"
import TypographyBold from "../../components/TypographyBold"

import StarIcon from "@material-ui/icons/Star"
import RoomIcon from "@material-ui/icons/Room"
import TodayIcon from "@material-ui/icons/Today"
import MotorcycleIcon from "@material-ui/icons/Motorcycle"
import TimerIcon from "@material-ui/icons/Timer"
import MapIcon from "@material-ui/icons/Map"
import PhoneIcon from "@material-ui/icons/Phone"

import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import PropTypes from "prop-types"

import { pink, lightGreen } from "@material-ui/core/colors"

import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Checkbox from "@material-ui/core/Checkbox"
import { alertWarningError } from "../../components/Notificaciones"
import AddIcon from "@material-ui/icons/AddCircle"

import axios from "../../utils/axios"

import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import EditIcon from "@material-ui/icons/Edit"
import CheckIcon from "@material-ui/icons/Check"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import swal from "sweetalert"
import logo from "../../assets/images/logo.png"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        INVESDE
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

const valueDelivery = [
  {
    estado: "ABIERTO",
    desde: "10:30:00",
    hasta: "11:30:00",
  },
  {
    estado: "CERRADO",
    desde: "10:30:00",
    hasta: "11:30:00",
  },
]

export default function DetalleComercio() {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const dataProps = history.location.state
  const [value, setValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [dataUsuarios, setDataUsuarios] = useState({ content: [] })
  const [dataColaborador, setDataColaborador] = useState({ content: [] })
  const [dataMenu, setDataMenu] = useState({ content: [] })
  const [dataHistoricoVisitas, setDataHistoricoVisitas] = useState({ content: [] })
  const [dataHorario, setDataHorario] = useState({ content: [] })
  const [dataHorarioDelivery, setDataHorarioDelivery] = useState({
    content: valueDelivery,
  })
  const [dataZonaCobertura, setDataZonaCobertura] = useState({ content: [] })
  const [dataFotoLocal, setDataFotoLocal] = useState({ content: [] })
  const [fotoFachada, setFotoFachada] = useState({ content: [] })
  const [fotoInterior, setFotoInterior] = useState({ content: [] })
  const [fotoSecundaria, setFotoSecundaria] = useState({ content: [] })
  const [dataConfiguracion, setDataConfiguracion] = useState({})

  useEffect(() => {
    if (dataProps?.id_cliente) {
      getUsuarios(dataProps?.id_cliente)
      getColaboradores(dataProps?.id_cliente)
      getMenu(dataProps?.id_cliente)
      getHorarios(dataProps?.id_cliente)
      getFotos(dataProps?.id_cliente)
      getHistoricos(dataProps?.id_cliente)
      getDelivery(dataProps?.id_cliente)
      getConfiguracion(dataProps?.id_cliente)
      if (dataProps?.fotoFachada) {
        let fotoFachada = dataProps?.fotoFachada.split(",")
        setFotoFachada({ ...fotoFachada, content: fotoFachada })
      }

      if (dataProps?.fotoInteriores) {
        let fotoInteriores = dataProps?.fotoInteriores.split(",")
        setFotoInterior({ ...fotoInterior, content: fotoInteriores })
      }

      if (dataProps?.fotoSecuencia) {
        let fotoSecuencia = dataProps?.fotoSecuencia.split(",")
        setFotoSecundaria({ ...fotoSecundaria, content: fotoSecuencia })
      }
    }
  }, [])
  const getUsuarios = async (props) => {
    setIsLoading(true)
    let url = "colaboradores/usuario-comercio-listar"
    try {
      const response = await axios.post(url, {
        idComercio: props,
      })
      let status = response.status
      if (status === 200) {
        const user = response.data
        setDataUsuarios({ ...dataUsuarios, content: user?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getColaboradores = async (props) => {
    setIsLoading(true)
    let url = "empleados/empleado-listar/"
    try {
      const response = await axios.post(url, {
        idComercio: props,
        idColaborador: null,
      })
      let status = response.status
      if (status === 200) {
        const funcionario = response.data
        setDataColaborador({ ...dataColaborador, content: funcionario?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getMenu = async (props) => {
    setIsLoading(true)
    let url = "menus/menu-listar/" + props
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const menu = response.data
        setDataMenu({ ...dataMenu, content: menu?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const getHorarios = async (props) => {
    setIsLoading(true)
    let url = "horarios/horario-listar"
    try {
      const response = await axios.post(url, {
        idComercio: props,
        esEntrega: false,
      })
      let status = response.status
      if (status === 200) {
        const hora = response.data
        setDataHorario({ ...dataHorario, content: hora?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getFotos = async (props) => {
    setIsLoading(true)
    let url = "comercios/fotos-adicional/" + props
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const fotosResponse = response.data?.result
        let fotos = []

        fotosResponse?.forEach((value) => {
          let object = {
            id_cliente: value?.id_cliente,
            foto_sec: value?.foto_sec?.split(","),
          }
          fotos.push(object)
        })
        setDataFotoLocal({ ...dataFotoLocal, content: fotos })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getHistoricos = async (props) => {
    setIsLoading(true)
    let url = "visitas/historico/" + props
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const historico = response.data
        setDataHistoricoVisitas({
          ...dataHistoricoVisitas,
          content: historico?.result,
        })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getDelivery = async (props) => {
    setIsLoading(true)
    let url = "comercios/zona-cobertura/" + props
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const deliveryResponse = response.data

        setDataZonaCobertura({
          ...dataZonaCobertura,
          content: deliveryResponse?.result,
        })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getConfiguracion = async (props) => {
    setIsLoading(true)
    let url = "comercios/config-pedido/" + props
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const confResponse = response.data

        setDataConfiguracion(confResponse)

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const columnsColaborador = [
    {
      title: "ID",
      field: "idEmpleado",
      width: "1%",
      hidden: true,
    },
    {
      title: "Imagen",
      field: "urlFoto",
      align: "center",
      render: (rowData) => (
        <img
          style={{ height: 40, borderRadius: "20%" }}
          src={`${rowData?.urlFoto}`}
          alt="Pendiente de Recarga"
          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
        />
      ),
      width: "10%",
    },
    {
      title: "Nombre",
      field: "nombre",
      //  width: "15%",
    },
    {
      title: "Cargo",
      field: "cargo",
      width: "20%",
    },

    {
      title: "Biografía",
      field: "biografia",
    },
  ]
  const columnsMenu = [
    {
      title: "ID",
      field: "id_menu",
      width: "1%",
      hidden: true,
    },
    {
      title: "Imagen",
      field: "urlFoto",
      align: "center",
      render: (rowData) => (
        <img
          style={{ height: 40, borderRadius: "20%" }}
          src={`${rowData?.urlFoto}`}
          alt="Pendiente de Recarga"

          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
        />
      ),
      width: "10%",
    },
    {
      title: "Nombre",
      field: "nombre",
      //  width: "15%",
    },
    {
      title: "Categoría",
      field: "categoria",
      width: "20%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={false}
          variant="standard"
          severity="info"
          style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
        >
          {rowData?.categoria}{" "}
        </Alert>
      ),
    },
    {
      title: "Precio",
      field: "precio",
      width: "20%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={false}
          variant="standard"
          severity="success"
          style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
        >
          {"GS. " + parseFloat(rowData?.precio).toLocaleString("es")}{" "}
        </Alert>
      ),
    },
  ]

  const columnsHistoricoVisita = [
    {
      title: "ID",
      field: "id_cliente",
      width: "1%",
      hidden: true,
    },

    {
      title: "Comentario",
      field: "comentario_visita",
      //  width: "15%",
    },
    {
      title: "Tipo visita",
      field: "medio_visita_solicitada",
      width: "15%",
    },
    {
      title: "Fecha",
      field: "fecha_visita",
      width: "20%",
      render: (rowData) => (
        <Chip
          icon={<TodayIcon />}
          color="secondary"
          label={rowData?.fecha_visita}
          size="small"
        />
      ),
    },

    {
      title: "Horas",
      field: "hora_visita",
      width: "10%",
      align: "center",
      render: (rowData) => (
        <Chip
          icon={<TimerIcon />}
          color="primary"
          label={rowData?.hora_visita}
          size="small"
        />
      ),
    },

    {
      title: "Estado",
      field: "estado",
      width: "10%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={false}
          variant="standard"
          severity="success"
          style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
        >
          {rowData?.estado}{" "}
        </Alert>
      ),
    },
  ]

  const columnsHorario = [
    {
      title: "ID",
      field: "id_horario",
      width: "1%",
      hidden: true,
    },

    {
      title: "Día",
      field: "dia",
      //  width: "15%",
    },
    {
      title: "Horario",
      field: "desde",
      width: "35%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={false}
          variant="standard"
          severity="info"
          style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
        >
          {rowData?.desde + " a " + rowData?.hasta}
        </Alert>
      ),
    },
    {
      title: "Estado",
      field: "estado",
      width: "20%",
      align: "center",
      render: (rowData) =>
        rowData.estado === "ABIERTO" ? (
          <Alert
            icon={false}
            variant="filled"
            severity="success"
            style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
          >
            {rowData.estado}
          </Alert>
        ) : (
          <Alert
            icon={false}
            variant="filled"
            severity="error"
            style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
          >
            {rowData.estado}
          </Alert>
        ),
    },
  ]

  const columnsZonaCobertura = [
    {
      title: "ID",
      field: "id_zona_cobertura",
      width: "1%",
      hidden: true,
    },

    {
      title: "Zona",
      field: "nombre",
      //  width: "15%",
    },
    {
      title: "Km a la redonda",
      field: "distancia",
      width: "20%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={false}
          variant="standard"
          severity="info"
          style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
        >
          {rowData?.distancia}
        </Alert>
      ),
    },
    {
      title: "Precio",
      field: "costo",
      width: "20%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={false}
          variant="standard"
          severity="success"
          style={{ justifyContent: "center", padding: "0px 10px 0px 10px" }}
        >
          {rowData?.costo}
        </Alert>
      ),
    },
  ]

  const columnsUsuarios = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true,
    },
    {
      title: "Imagen",
      field: "urlFoto",
      align: "center",
      render: (rowData) => (
        <img
          style={{ height: 40, borderRadius: "20%" }}
          src={`${rowData?.urlFoto}`}
          alt="Pendiente de Recarga"

          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
        />
      ),
      width: "10%",
    },
    {
      title: "Nombre",
      field: "nombre",
      //  width: "15%",
    },
    {
      title: "Apellido",
      field: "apellido",
      //  width: "15%",
    },
    {
      title: "Correo",
      field: "correo",
      width: "15%",
    },
    {
      title: "Dueño",
      field: "esPropietario",
      width: "10%",
      align: "center",
      render: (rowData) =>
        rowData.esPropietario ? (
          <Alert
            icon={false}
            variant="standard"
            severity="success"
            style={{ justifyContent: "center", padding: "0px 10px" }}
          >
            SI
          </Alert>
        ) : (
          <Alert
            icon={false}
            variant="standard"
            severity="error"
            style={{ justifyContent: "center", padding: "0px 10px" }}
          >
            NO
          </Alert>
        ),
    },

    {
      title: "Driver",
      field: "esDriver",
      width: "10%",
      align: "center",
      render: (rowData) =>
        rowData.esDriver ? (
          <Alert
            icon={false}
            variant="standard"
            severity="success"
            style={{ justifyContent: "center", padding: "0px 10px" }}
          >
            SI
          </Alert>
        ) : (
          <Alert
            icon={false}
            variant="standard"
            severity="error"
            style={{ justifyContent: "center", padding: "0px 10px" }}
          >
            NO
          </Alert>
        ),
    },
  ]

  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    // maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    //   draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres  ${
        props.data?.estado === "ACTIVO" ? "inactivar" : "activar "
      }  a ${props.data?.nombre} ${props.data?.apellido}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminarUsuario(props.data)
      }
    })
  }

  const eliminarUsuario = async (props) => {
    setIsLoading(true)
    let url = "colaboradores/usuario-comercio-inactivar"
    try {
      const response = await axios.post(url, {
        idColaborador: props?.id,
      })
      let status = response.status
      if (status === 200) {
        getUsuarios(props?.idComercio)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const childrenAccions = (props) => {
    return (
      <>
        {props?.data?.estado === "ACTIVO" ? (
          <>
            <Box pl={1} pr={1}>
              <Chip
                onClick={(e) => handleInactivar(e, props)}
                avatar={
                  <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                    <HighlightOffIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                  </Avatar>
                }
                label="Inactivar"
                variant="outlined"
                color="error"
              />
            </Box>
          </>
        ) : (
          <>
            <Box pl={1} pr={1}>
              <Chip
                onClick={(e) => handleInactivar(e, props)}
                avatar={
                  <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                    <CheckIcon fontSize="small" style={{ color: "#ffffff" }} />
                  </Avatar>
                }
                label="Activar"
                variant="outlined"
                color="primary"
              />
            </Box>
          </>
        )}

        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => history.push("./editar-usuario/", props.data)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Editar"
            variant="outlined"
            color="secondary"
          />
        </Box>
      </>
    )
  }

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 2 }}>
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  variant="rounded"
                  alt="Remy Sharp"
                  src={dataProps?.urlFoto}
                />
              }
              title={dataProps?.nomb_fantasia}
              subheader={
                <Chip
                  label={"#ID " + dataProps?.id_cliente}
                  size="small"
                  color="secondary"
                />
              }
            />

            <CardContent style={{ padding: 5 }}>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Box p={1}>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Alert
                          icon={<StarIcon fontSize="inherit" />}
                          variant="outlined"
                          severity="error"
                          style={{
                            justifyContent: "center",
                            padding: "0px 10px 0px 10px",
                          }}
                        >
                          {dataProps?.valoracion}
                        </Alert>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Alert
                          icon={<RoomIcon fontSize="inherit" />}
                          variant="outlined"
                          severity="warning"
                          style={{
                            justifyContent: "center",
                            padding: "0px 10px 0px 10px",
                          }}
                        >
                          {dataProps?.distancia}
                        </Alert>
                      </Grid>

                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Alert
                          icon={<MotorcycleIcon fontSize="inherit" />}
                          variant="outlined"
                          severity="success"
                          style={{
                            justifyContent: "center",
                            padding: "0px 10px 0px 10px",
                          }}
                        >
                          {parseFloat(dataProps?.costo_entrega).toLocaleString("es")}{" "}
                          GS
                        </Alert>
                      </Grid>

                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Alert
                          icon={<TimerIcon fontSize="inherit" />}
                          variant="outlined"
                          severity="info"
                          style={{
                            justifyContent: "center",
                            padding: "0px 10px 0px 10px",
                          }}
                        >
                          {dataProps?.hora_entrega}
                        </Alert>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box p={1}>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Link
                          target="_blank"
                          href={`https://www.google.com/maps/search/?api=1&query=${dataProps?.latitud},${dataProps?.longitud}`}
                          color="inherit"
                        >
                          {" "}
                          <Alert
                            icon={<MapIcon fontSize="inherit" />}
                            variant="standard"
                            severity="error"
                            style={{
                              justifyContent: "center",
                              padding: "0px 10px 0px 10px",
                            }}
                          >
                            Como llegar{" "}
                          </Alert>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Alert
                          icon={<PhoneIcon fontSize="inherit" />}
                          variant="standard"
                          severity="success"
                          style={{
                            justifyContent: "center",
                            padding: "0px 10px 0px 10px",
                          }}
                        >
                          {dataProps?.nro_contacto}
                        </Alert>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    style={{
                      flexGrow: 1,
                      backgroundColor: theme.palette.background.paper,
                      display: "flex",
                      // height: 350,
                    }}
                  >
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                      style={{
                        borderRight: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <Tab label="Colaboradores" {...a11yProps(0)} />
                      <Tab label="Menu" {...a11yProps(1)} />
                      <Tab label="Fotos adicionales" {...a11yProps(2)} />
                      <Tab label="Historico Visitas" {...a11yProps(3)} />
                      <Tab label="Horario Comercio" {...a11yProps(4)} />
                      <Tab label="Horario Delivery" {...a11yProps(5)} />
                      <Tab label="Delivery Cobertura" {...a11yProps(6)} />
                      <Tab label="Información" {...a11yProps(7)} />
                      <Tab label="Historia" {...a11yProps(8)} />
                      <Tab label="Configuración" {...a11yProps(9)} />
                      <Tab label="Usuarios" {...a11yProps(10)} />
                    </Tabs>
                    <TabPanel value={value} index={0} style={{ width: "100%" }}>
                      <MakeTables
                        isLoading={isLoading}
                        title=""
                        columns={columnsColaborador}
                        data={dataColaborador.content}
                        actions={actions}
                        classes={classes}
                        options={options}
                        componentsAssets={{
                          classes,
                          // editar,
                          //eliminar,
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1} style={{ width: "100%" }}>
                      <MakeTables
                        isLoading={isLoading}
                        title=""
                        columns={columnsMenu}
                        data={dataMenu.content}
                        actions={actions}
                        classes={classes}
                        options={options}
                        componentsAssets={{
                          classes,
                          // editar,
                          //eliminar,
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={2} style={{ width: "100%" }}>
                      <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        {dataFotoLocal.content.length > 0 &&
                          dataFotoLocal.content.map((value) => {
                            return value?.foto_sec.map((imagen) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Link
                                      target="_blank"
                                      href={`${imagen} `}
                                      color="inherit"
                                    >
                                      <Card className={classes.root}>
                                        <CardMedia
                                          width="30%"
                                          title={imagen}
                                          height="30%"
                                          component="img"
                                          src={imagen}
                                        />
                                      </Card>
                                    </Link>
                                  </Grid>
                                </>
                              )
                            })
                          })}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3} style={{ width: "100%" }}>
                      <MakeTables
                        isLoading={isLoading}
                        title=""
                        columns={columnsHistoricoVisita}
                        data={dataHistoricoVisitas.content}
                        actions={actions}
                        classes={classes}
                        options={options}
                        componentsAssets={{
                          classes,
                          // editar,
                          //eliminar,
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={4} style={{ width: "100%" }}>
                      <MakeTables
                        isLoading={isLoading}
                        title=""
                        columns={columnsHorario}
                        data={dataHorario.content}
                        actions={actions}
                        classes={classes}
                        options={options}
                        componentsAssets={{
                          classes,
                          // editar,
                          //eliminar,
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={5} style={{ width: "100%" }}>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Lunes
                      </TypographyBold>
                      <Box mt={2} mb={4}>
                        <Grid container direction="row" spacing={1}>
                          {dataHorarioDelivery.content.length > 0 ? (
                            dataHorarioDelivery.content.map((value) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card>
                                      <CardHeader
                                        title={
                                          value.estado === "ABIERTO" ? (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="success"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          ) : (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="error"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          )
                                        }
                                      />
                                      <CardActions>
                                        <Grid
                                          container
                                          direction="row"
                                          justify="center"
                                          alignContent="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Alert
                                              icon={false}
                                              variant="standard"
                                              severity="info"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value?.desde + " a " + value?.hasta}
                                            </Alert>
                                          </Grid>
                                        </Grid>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              )
                            })
                          ) : (
                            <Chip
                              label={"SIN HORARIOS"}
                              variant="default"
                              color="secondary"
                              size="small"
                              // icon={<AssignmentIcon />}
                            />
                          )}
                        </Grid>
                      </Box>

                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Martes
                      </TypographyBold>
                      <Box mt={2} mb={4}>
                        <Grid container direction="row" spacing={1}>
                          {dataHorarioDelivery.content.length > 0 ? (
                            dataHorarioDelivery.content.map((value) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card>
                                      <CardHeader
                                        title={
                                          value.estado === "ABIERTO" ? (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="success"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          ) : (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="error"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          )
                                        }
                                      />
                                      <CardActions>
                                        <Grid
                                          container
                                          direction="row"
                                          justify="center"
                                          alignContent="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Alert
                                              icon={false}
                                              variant="standard"
                                              severity="info"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value?.desde + " a " + value?.hasta}
                                            </Alert>
                                          </Grid>
                                        </Grid>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              )
                            })
                          ) : (
                            <Chip
                              label={"SIN HORARIOS"}
                              variant="default"
                              color="secondary"
                              size="small"
                              // icon={<AssignmentIcon />}
                            />
                          )}
                        </Grid>
                      </Box>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Miercoles
                      </TypographyBold>
                      <Box mt={2} mb={4}>
                        <Grid container direction="row" spacing={1}>
                          {dataHorarioDelivery.content.length > 0 ? (
                            dataHorarioDelivery.content.map((value) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card>
                                      <CardHeader
                                        title={
                                          value.estado === "ABIERTO" ? (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="success"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          ) : (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="error"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          )
                                        }
                                      />
                                      <CardActions>
                                        <Grid
                                          container
                                          direction="row"
                                          justify="center"
                                          alignContent="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Alert
                                              icon={false}
                                              variant="standard"
                                              severity="info"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value?.desde + " a " + value?.hasta}
                                            </Alert>
                                          </Grid>
                                        </Grid>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              )
                            })
                          ) : (
                            <Chip
                              label={"SIN HORARIOS"}
                              variant="default"
                              color="secondary"
                              size="small"
                              // icon={<AssignmentIcon />}
                            />
                          )}
                        </Grid>
                      </Box>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Jueves
                      </TypographyBold>
                      <Box mt={2} mb={4}>
                        <Grid container direction="row" spacing={1}>
                          {dataHorarioDelivery.content.length > 0 ? (
                            dataHorarioDelivery.content.map((value) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card>
                                      <CardHeader
                                        title={
                                          value.estado === "ABIERTO" ? (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="success"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          ) : (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="error"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          )
                                        }
                                      />
                                      <CardActions>
                                        <Grid
                                          container
                                          direction="row"
                                          justify="center"
                                          alignContent="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Alert
                                              icon={false}
                                              variant="standard"
                                              severity="info"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value?.desde + " a " + value?.hasta}
                                            </Alert>
                                          </Grid>
                                        </Grid>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              )
                            })
                          ) : (
                            <Chip
                              label={"SIN HORARIOS"}
                              variant="default"
                              color="secondary"
                              size="small"
                              // icon={<AssignmentIcon />}
                            />
                          )}
                        </Grid>
                      </Box>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Viernes
                      </TypographyBold>
                      <Box mt={2} mb={4}>
                        <Grid container direction="row" spacing={1}>
                          {dataHorarioDelivery.content.length > 0 ? (
                            dataHorarioDelivery.content.map((value) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card>
                                      <CardHeader
                                        title={
                                          value.estado === "ABIERTO" ? (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="success"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          ) : (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="error"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          )
                                        }
                                      />
                                      <CardActions>
                                        <Grid
                                          container
                                          direction="row"
                                          justify="center"
                                          alignContent="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Alert
                                              icon={false}
                                              variant="standard"
                                              severity="info"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value?.desde + " a " + value?.hasta}
                                            </Alert>
                                          </Grid>
                                        </Grid>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              )
                            })
                          ) : (
                            <Chip
                              label={"SIN HORARIOS"}
                              variant="default"
                              color="secondary"
                              size="small"
                              // icon={<AssignmentIcon />}
                            />
                          )}
                        </Grid>
                      </Box>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Sabado
                      </TypographyBold>
                      <Box mt={2} mb={4}>
                        <Grid container direction="row" spacing={1}>
                          {dataHorarioDelivery.content.length > 0 ? (
                            dataHorarioDelivery.content.map((value) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card>
                                      <CardHeader
                                        title={
                                          value.estado === "ABIERTO" ? (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="success"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          ) : (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="error"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          )
                                        }
                                      />
                                      <CardActions>
                                        <Grid
                                          container
                                          direction="row"
                                          justify="center"
                                          alignContent="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Alert
                                              icon={false}
                                              variant="standard"
                                              severity="info"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value?.desde + " a " + value?.hasta}
                                            </Alert>
                                          </Grid>
                                        </Grid>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              )
                            })
                          ) : (
                            <Chip
                              label={"SIN HORARIOS"}
                              variant="default"
                              color="secondary"
                              size="small"
                              // icon={<AssignmentIcon />}
                            />
                          )}
                        </Grid>
                      </Box>
                      <TypographyBold
                        variant="body2"
                        display="block"
                        color="textSecondary"
                      >
                        Domingo
                      </TypographyBold>
                      <Box mt={2} mb={4}>
                        <Grid container direction="row" spacing={1}>
                          {dataHorarioDelivery.content.length > 0 ? (
                            dataHorarioDelivery.content.map((value) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <Card>
                                      <CardHeader
                                        title={
                                          value.estado === "ABIERTO" ? (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="success"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          ) : (
                                            <Alert
                                              icon={false}
                                              variant="filled"
                                              severity="error"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value.estado}
                                            </Alert>
                                          )
                                        }
                                      />
                                      <CardActions>
                                        <Grid
                                          container
                                          direction="row"
                                          justify="center"
                                          alignContent="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Alert
                                              icon={false}
                                              variant="standard"
                                              severity="info"
                                              style={{
                                                justifyContent: "center",
                                                padding: "0px 10px 0px 10px",
                                              }}
                                            >
                                              {value?.desde + " a " + value?.hasta}
                                            </Alert>
                                          </Grid>
                                        </Grid>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              )
                            })
                          ) : (
                            <Chip
                              label={"SIN HORARIOS"}
                              variant="default"
                              color="secondary"
                              size="small"
                              // icon={<AssignmentIcon />}
                            />
                          )}
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={6} style={{ width: "100%" }}>
                      <MakeTables
                        isLoading={isLoading}
                        title=""
                        columns={columnsZonaCobertura}
                        data={dataZonaCobertura.content}
                        actions={actions}
                        classes={classes}
                        options={options}
                        componentsAssets={{
                          classes,
                          // editar,
                          //eliminar,
                        }}
                      />
                    </TabPanel>

                    <TabPanel value={value} index={7} style={{ width: "100%" }}>
                      <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                      >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Nombre
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.nombre}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Ciudad
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.ciudad}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Barrio
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.barrio}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Razón Social
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.razon_social}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            RUC
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.ruc}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Propietario
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.nomb_encargado}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Calle principal
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.callePrincipal}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Calle Secundaria
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.calleSecundaria}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Whatsapp
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.whatsapp}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Dirección
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {`https://www.google.com/maps/search/?api=1&query=${dataProps?.latitud},${dataProps?.longitud} `}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Cantidad Personal
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.cantidadPersonal
                              ? dataProps?.cantidadPersonal
                              : "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Fricción con Monchis
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.friccionMonchis
                              ? dataProps?.friccionMonchis
                              : "Sin datos"}
                          </TypographyBold>
                        </Grid> */}

                        {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Fricción con PedidosYa
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.friccionPedidosYa
                              ? dataProps?.friccionPedidosYa
                              : "Sin datos"}
                          </TypographyBold>
                        </Grid> */}

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Tipo Partner
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.tipoPartner
                              ? dataProps?.tipoPartner
                              : "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Tiene Delivery
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.tieneDelivery
                              ? dataProps?.tieneDelivery
                              : "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Tipo Delivery
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.tipoDelivery
                              ? dataProps?.tipoDelivery
                              : "Sin datos"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Fecha de creación
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.fechaCreacion}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Fecha de inactivación
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.fechaInactivacion
                              ? dataProps?.fechaInactivacion
                              : "----"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Estaría interesado en un préstamo operativo
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.prestamo ? "SI" : "NO"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Productos que necesita
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.productoNecesita
                              ? dataProps?.productoNecesita
                              : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Tiene IVA
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.iva ? "SI" : "NO"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            IVA Antiguedad
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.ivaAntiguedad
                              ? dataProps?.ivaAntiguedad
                              : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Tiene patente
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.patente ? "SI" : "NO"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Patente Antiguedad
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.patenteAntiguedad
                              ? dataProps?.patenteAntiguedad
                              : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Canon
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.canon ? "SI" : "NO"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Canon Antiguedad
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.canonAntiguedad
                              ? dataProps?.canonAntiguedad
                              : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Tipo Empresa
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.tipoEmpresa
                              ? dataProps?.tipoEmpresa
                              : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Antiguedad
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.antiguedad
                              ? dataProps?.antiguedad
                              : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Scoring
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.scoring ? dataProps?.scoring : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Servicio de Aguatería
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.servicioAguateria
                              ? dataProps?.servicioAguateria
                              : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            NIS ANDE
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.nis ? dataProps?.nis : "SIN DATOS"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Card className={classes.root}>
                            <CardHeader
                              avatar={
                                <Avatar aria-label="recipe">
                                  <PhotoCameraIcon />
                                </Avatar>
                              }
                              title="FOTO NIS"
                              // subheader="September 14, 2016"
                            />
                            <CardMedia
                              width="30%"
                              title={dataProps?.nomb_fantasia}
                              height="30%"
                              component="img"
                              src={dataProps?.fotoNis ? dataProps?.fotoNis : logo}
                            />
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Card className={classes.root}>
                            <CardHeader
                              avatar={
                                <Avatar aria-label="recipe">
                                  <PhotoCameraIcon />
                                </Avatar>
                              }
                              title="FOTO AGUATERIA"
                              // subheader="September 14, 2016"
                            />
                            <CardMedia
                              width="30%"
                              title={dataProps?.nomb_fantasia}
                              height="30%"
                              component="img"
                              src={
                                dataProps?.fotoAguateria
                                  ? dataProps?.fotoAguateria
                                  : logo
                              }
                            />
                          </Card>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={8} style={{ width: "100%" }}>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Nuesta historia
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.historia}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid container justifyContent="center" spacing={3}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              {dataProps?.urlFoto === null ? (
                                <></>
                              ) : (
                                <Card className={classes.root}>
                                  <CardHeader
                                    avatar={
                                      <Avatar aria-label="recipe">
                                        <PhotoCameraIcon />
                                      </Avatar>
                                    }
                                    title="FOTO LOCAL"
                                  />
                                  <CardMedia
                                    width="30%"
                                    title={dataProps?.nomb_fantasia}
                                    height="30%"
                                    component="img"
                                    src={
                                      dataProps?.urlFoto ? dataProps?.urlFoto : null
                                    }
                                  />
                                </Card>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <Card className={classes.root}>
                                <CardHeader
                                  avatar={
                                    <Avatar aria-label="recipe">
                                      <PhotoCameraIcon />
                                    </Avatar>
                                  }
                                  title="FOTO PORTADA"
                                  // subheader="September 14, 2016"
                                />
                                <CardMedia
                                  width="30%"
                                  title={dataProps?.nomb_fantasia}
                                  height="30%"
                                  component="img"
                                  src={
                                    dataProps?.fotoPortada
                                      ? dataProps?.fotoPortada
                                      : logo
                                  }
                                />
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={3}
                          >
                            <>
                              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                {dataProps?.fotoFachada === null ? (
                                  <></>
                                ) : (
                                  <Card className={classes.root}>
                                    <CardHeader
                                      avatar={
                                        <Avatar aria-label="recipe">
                                          <PhotoCameraIcon />
                                        </Avatar>
                                      }
                                      title="FOTO FACHADA"
                                    />{" "}
                                    <CardMedia
                                      width="30%"
                                      title={value}
                                      height="30%"
                                      component="img"
                                      src={
                                        dataProps?.fotoFachada
                                          ? dataProps?.fotoFachada
                                          : null
                                      }
                                    />
                                  </Card>
                                )}
                              </Grid>
                            </>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={3}
                          >
                            {fotoInterior.content.length > 0 &&
                              fotoInterior.content.map((value) => {
                                return (
                                  <>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <Card className={classes.root}>
                                        <CardHeader
                                          avatar={
                                            <Avatar aria-label="recipe">
                                              <PhotoCameraIcon />
                                            </Avatar>
                                          }
                                          title="FOTO INTERIOR"
                                        />{" "}
                                        <Link
                                          target="_blank"
                                          href={`${value} `}
                                          color="inherit"
                                        >
                                          <CardMedia
                                            width="30%"
                                            title={value}
                                            height="30%"
                                            component="img"
                                            src={value}
                                          />
                                        </Link>
                                      </Card>
                                    </Grid>
                                  </>
                                )
                              })}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid
                            container
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={3}
                          >
                            {fotoSecundaria.content.length > 0 &&
                              fotoSecundaria.content.map((value) => {
                                return (
                                  <>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                      <Card className={classes.root}>
                                        <CardHeader
                                          avatar={
                                            <Avatar aria-label="recipe">
                                              <PhotoCameraIcon />
                                            </Avatar>
                                          }
                                          title="FOTO SECUNDARIA"
                                        />{" "}
                                        <Link
                                          target="_blank"
                                          href={`${value} `}
                                          color="inherit"
                                        >
                                          <CardMedia
                                            width="30%"
                                            title={value}
                                            height="30%"
                                            component="img"
                                            src={value}
                                          />
                                        </Link>
                                      </Card>
                                    </Grid>
                                  </>
                                )
                              })}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Link
                            target="_blank"
                            href={`${dataProps?.facebook} `}
                            color="inherit"
                          >
                            <Alert
                              icon={<FacebookIcon fontSize="inherit" />}
                              variant="outlined"
                              severity="info"
                              style={{
                                justifyContent: "center",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              Facebook
                            </Alert>
                          </Link>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Link
                            target="_blank"
                            href={`${dataProps?.instagram} `}
                            color="inherit"
                          >
                            <Alert
                              icon={<InstagramIcon fontSize="inherit" />}
                              variant="outlined"
                              severity="warning"
                              style={{
                                justifyContent: "center",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              Instagram
                            </Alert>
                          </Link>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Link
                            target="_blank"
                            href={`${dataProps?.twitter} `}
                            color="inherit"
                          >
                            <Alert
                              icon={<TwitterIcon fontSize="inherit" />}
                              variant="outlined"
                              severity="info"
                              style={{
                                justifyContent: "center",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              Twitter
                            </Alert>
                          </Link>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={9} style={{ width: "100%" }}>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Forma de pago
                          </Typography>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <FormGroup>
                              {dataConfiguracion?.tipo_pago?.map((value) => {
                                return (
                                  <>
                                    <FormControlLabel
                                      disabled
                                      id={value?.id_tp_pago}
                                      control={
                                        <Checkbox
                                          checked={value?.existe}
                                          name={value?.nombre}
                                        />
                                      }
                                      label={value?.nombre}
                                    />
                                  </>
                                )
                              })}
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Tipo pedido
                          </Typography>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <FormGroup>
                              {dataConfiguracion?.tipo_pedido?.map((value) => {
                                return (
                                  <FormControlLabel
                                    disabled
                                    id={value?.id_tp_pedido}
                                    control={
                                      <Checkbox
                                        checked={value?.existe}
                                        name={value?.nombre}
                                      />
                                    }
                                    label={value?.nombre}
                                  />
                                )
                              })}
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={10} style={{ width: "100%" }}>
                      <MakeTables
                        isLoading={isLoading}
                        title={
                          <Button
                            size="small"
                            variant="outlined"
                            style={{ color: pink[600] }}
                            startIcon={<AddIcon />}
                            onClick={() =>
                              history.push("./nuevo-usuario/", {
                                id_cliente: dataProps?.id_cliente,
                              })
                            }
                          >
                            Agregar
                          </Button>
                        }
                        columns={columnsUsuarios}
                        data={dataUsuarios.content}
                        actions={actions}
                        classes={classes}
                        options={options}
                        componentsAssets={{
                          classes,
                          // editar,
                          //eliminar,
                          childrenAccions,
                        }}
                      />
                    </TabPanel>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignContent="center"
                spacing={2}
              >
                <Grid item>
                  <BotonGris
                    size="small"
                    color="default"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Salir
                  </BotonGris>{" "}
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Box pb={2} pt={6} className={classes.footerBotttom}>
        <Copyright />
      </Box>
    </>
  )
}
