import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Chip } from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import Switch from "@material-ui/core/Switch"
import Tooltip from "@material-ui/core/Tooltip"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"

import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import EditIcon from "@material-ui/icons/Edit"

export default function ListaColaboradores() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })
  const [state, setState] = useState({})

  useEffect(() => {
    getColaboradores()
  }, [])

  const getColaboradores = async (props) => {
    setIsLoading(true)
    let url = "users/collaborator/"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const colaboradores = response.data
        setData({ ...data, content: colaboradores })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nuevo-colaborador")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true,
    },
    {
      title: "Imagen",
      align: "center",
      render: (rowData) =>
        rowData.mainPicture ? (
          <img
            style={{ height: 40, borderRadius: "20%" }}
            src={rowData.mainPicture.url}
            alt="Pendiente de Recarga"

            // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
          />
        ) : (
          <></>
        ),
      width: "10%",
    },
    {
      title: "Nombre",
      field: "firstName",
      //  width: "15%",
    },
    {
      title: "Apellido",
      field: "lastName",
      // width: "10%",
    },

    {
      title: "Correo",
      field: "email",
      width: "20%",
    },

    {
      title: "Teléfono",
      field: "phoneNumber",
      width: "12%",
    },
    {
      title: "Tipo usuario",
      field: "role",
      width: "15%",
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar a ${props.data?.nombre} ${props.data?.apellido}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminarColaborador(props.data)
      }
    })
  }

  const eliminarColaborador = async (props) => {
    setIsLoading(true)
    let url = "colaboradores/colaborador-inactivar"
    try {
      const response = await axios.post(url, {
        idColaborador: props?.id,
      })
      let status = response.status
      if (status === 200) {
        getColaboradores()
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleInactivar(e, props)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <HighlightOffIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Inactivar"
            variant="outlined"
            color="error"
          />
        </Box>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => history.push("./editar-colaborador/", props.data)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Editar"
            variant="outlined"
            color="secondary"
          />
        </Box>
      </>
    )
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              // editar,
              childrenAccions,
            }}
          />
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
