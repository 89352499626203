import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { useStyles } from "../../assets/styles/CustomStyles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"
import { useHistory } from "react-router-dom"
import Alert from "@material-ui/lab/Alert"
import MapIcon from "@material-ui/icons/Map"

import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

import TypographyBold from "../../components/TypographyBold"
import { alertWarningError } from "../../components/Notificaciones"

import swal from "sweetalert"

import axios from "../../utils/axios"
import { visitStatusDictionary } from "../../utils/dictionaries"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        INVESDE
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0") // Los meses son indexados desde 0
  const year = String(date.getFullYear()) // Obtener los últimos dos dígitos del año
  return `${day}/${month}/${year}`
}

export default function DetalleDeRecorrido() {
  const classes = useStyles()
  const history = useHistory()
  const dataProps = history.location.state
  const [isLoading, setIsLoading] = useState(false)
  const [dataFotoLocal, setDataFotoLocal] = useState({ content: [] })

  useEffect(() => {
    if (dataProps?.id_cliente) {
      if (dataProps?.fotoSec) {
        let fotos = dataProps?.fotoSec.split(",")
        setDataFotoLocal({ ...dataFotoLocal, content: fotos })
      }

      //getFotos(dataProps?.id_cliente)
    }
  }, [])

  // const getFotos = async (props) => {
  //   setIsLoading(true)
  //   let url = "comercios/fotos-adicional/" + props
  //   try {
  //     const response = await axios.get(url)
  //     let status = response.status
  //     if (status === 200) {
  //       const fotosResponse = response.data?.result
  //       let fotos = []
  //
  //       fotosResponse?.forEach((value) => {
  //         let object = {
  //           id_cliente: value?.id_cliente,
  //           foto_sec: value?.foto_sec?.split(","),
  //         }
  //         fotos.push(object)
  //       })
  //       setDataFotoLocal({ ...dataFotoLocal, content: fotos })
  //       setIsLoading(false)
  //     }
  //   } catch (error) {
  //     setIsLoading(false)
  //     if (error.response) {
  //       alertWarningError(error.response)
  //     }
  //   }
  // }

  const handleInactivar = () => {
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres finalizar  ${dataProps?.commerce.name}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then(async (willDelete) => {
      if (willDelete) {
        await finalizar(dataProps?.id)
      }
    })
  }

  const finalizar = async (props) => {
    setIsLoading(true)
    try {
      const response = await axios.patch(
        `interest/update-visit-status/${props.id}`,
        { visitStatus: "FINISHED" },
      )
      let status = response.status
      if (status === 200) {
        await swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 2 }}>
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  variant="rounded"
                  alt="Remy Sharp"
                  src={dataProps?.visitorUser.mainPicture.url}
                />
              }
              title={dataProps?.cliente}
              subheader={
                <Chip
                  label={
                    "Celular: " +
                    (dataProps?.visitorUser.phoneNumber ?? "Sin celular")
                  }
                  size="small"
                  color="secondary"
                />
              }
            />

            <CardContent>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader subheader="Datos de la visita creada" />

                    <CardContent>
                      <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                      >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Nombre y Apellido
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.visitorUser.fullName}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Fecha visita
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {formatDate(dataProps?.assigmentDate) ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Hora visita
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.visitHour ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Medio Visita Solicitada
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.visitType ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Comentario Visita Solicitada
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.comment ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader subheader="Datos de la visita realizada" />

                    <CardContent>
                      <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                      >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Persona Contactada
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.contactedPerson ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Fecha Visita Realizada
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.trueVisitDate ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Hora Visita Realizada
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.trueVisitHour ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Medio Visita Realizada
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.trueVisitType ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Motivo Reagendado
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.rescheduleReason ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Estado visita
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {visitStatusDictionary[dataProps?.status] ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Ubicación
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.commerce.latLong ? (
                              <Link
                                target="_blank"
                                href={`https://www.google.com/maps/search/?api=1&query=${dataProps?.commerce.latLong.lat}%2C${dataProps?.commerce.latLong.long}`}
                                color="inherit"
                              >
                                {" "}
                                <Alert
                                  icon={<MapIcon fontSize="inherit" />}
                                  variant="standard"
                                  severity="error"
                                  style={{
                                    justifyContent: "center",
                                    padding: "0px 10px 0px 10px",
                                  }}
                                >
                                  https://www.google.com/maps/@$
                                  {dataProps?.commerce.latLong.lat},$
                                  {dataProps?.commerce.latLong.long} `{" "}
                                </Alert>
                              </Link>
                            ) : (
                              "Sin ubicación"
                            )}
                          </TypographyBold>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Salida
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.exitHour ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            Comentario Visita Realizada
                          </Typography>
                          <TypographyBold
                            variant="body2"
                            display="block"
                            color="textSecondary"
                          >
                            {dataProps?.visitCommentary ?? "Sin datos"}
                          </TypographyBold>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>*/}
                {/*  <Card>*/}
                {/*    <CardHeader subheader="Fotos de la visita" />*/}

                {/*    <CardContent>*/}
                {/*      <Grid*/}
                {/*        container*/}
                {/*        justify="flex-start"*/}
                {/*        alignItems="flex-start"*/}
                {/*        spacing={3}*/}
                {/*      >*/}
                {/*        {dataFotoLocal.content.length > 0 &&*/}
                {/*          dataFotoLocal.content.map((value) => {*/}
                {/*            return (*/}
                {/*              <>*/}
                {/*                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>*/}
                {/*                  <Link*/}
                {/*                    target="_blank"*/}
                {/*                    href={`${value} `}*/}
                {/*                    color="inherit"*/}
                {/*                  >*/}
                {/*                    <Card className={classes.root}>*/}
                {/*                      <CardMedia*/}
                {/*                        width="30%"*/}
                {/*                        title={value}*/}
                {/*                        height="30%"*/}
                {/*                        component="img"*/}
                {/*                        src={value}*/}
                {/*                        // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}comercios/${dataProps?.id_cliente}/file/${imagen}`}*/}
                {/*                      />*/}
                {/*                    </Card>*/}
                {/*                  </Link>*/}
                {/*                </Grid>*/}
                {/*              </>*/}
                {/*            )*/}
                {/*          })}*/}
                {/*      </Grid>*/}
                {/*    </CardContent>*/}
                {/*  </Card>*/}
                {/*</Grid>*/}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignContent="center"
                spacing={2}
              >
                <Grid item>
                  <BotonVerde
                    size="small"
                    color="primary"
                    //   startIcon={<ArrowBackIosIcon />}
                    onClick={() => {
                      handleInactivar()
                    }}
                  >
                    Finalizar
                  </BotonVerde>{" "}
                </Grid>
                <Grid item>
                  <BotonGris
                    size="small"
                    color="default"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Salir
                  </BotonGris>{" "}
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Box pb={2} pt={6} className={classes.footerBotttom}>
        <Copyright />
      </Box>
    </>
  )
}
