import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import InputLabel from "@material-ui/core/InputLabel"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import {
  alertWarningError,
  notificacionWarning,
} from "../../components/Notificaciones"
import BackdropCustom from "../../components/BackdropCustom"
import Upload from "rc-upload"
import IconButton from "@material-ui/core/IconButton"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import UserContext from "../../utils/user/UserContext"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import FormControl from "@material-ui/core/FormControl"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import DeleteIcon from "@material-ui/icons/Delete"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
const inicialValue = {
  nombre: "",
  apellido: "",
  email: "",
  password: "",
  numeroContacto: "",
  idTipoDocumento: 0,
  documento: "",
  idComercio: 0,
  esPropietario: false,
  esDriver: false,
}

export default function NuevoUsuario() {
  const history = useHistory()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const dataProps = history.location.state

  const [showPassword, setShowPassword] = useState(false)
  const [isLoadingTipoDocumento, setIsLoadingTipoDocumento] = useState(false)
  const [tipoDocumento, setTipoDocumento] = useState({})
  const [tipoDocumentoList, setTipoDocumentoList] = useState({ content: [] })
  const [colaborador, setColaborador] = useState(inicialValue)
  const [fotoPerfil, setFotoPerfil] = useState({})

  useEffect(() => {
    if (dataProps?.id_cliente) {
      getTipoDocumento()

      let copyInput = {
        ...colaborador,
        idComercio: dataProps?.id_cliente,
      }
      setColaborador(copyInput)
    }
  }, [])

  const getTipoDocumento = async () => {
    setIsLoadingTipoDocumento(true)
    let url = "common/documento-listar"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const tipoDoc = response.data?.result
        setTipoDocumentoList({ ...tipoDocumentoList, content: tipoDoc })

        setIsLoadingTipoDocumento(false)
      }
    } catch (error) {
      setIsLoadingTipoDocumento(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const onSelectTipoDocumento = (e, value) => {
    if (value && value?.id !== tipoDocumento?.id) {
      setTipoDocumento(value)
      let copyInput = { ...colaborador, idTipoDocumento: value?.id }
      setColaborador(copyInput)
    }

    if (value === null) {
      setTipoDocumento({})
      let copyInput = { ...colaborador, idTipoDocumento: 0 }
      setColaborador(copyInput)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const uploaderProps = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader()

      const duplicado = fotoPerfil?.file?.uid === file.uid

      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file)
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {
          let base64 = reader.result.split(";base64,")
          let documentoBase64 = base64[1]
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          }

          setFotoPerfil(imagen)
        }

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          alert("couldn't read the file")
        }
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      alert("onError", err)
    },
    capture: "josue",
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "auth/registro-comercio"

    try {
      const response = await axios.post(url, colaborador)
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        if (response.data?.ok) {
          if (Object.keys(fotoPerfil).length === 0) {
            setIsLoading(false)
            swal("¡OPERACIÓN EXITOSA!", {
              icon: "success",
              buttons: false,
              timer: 1500,
            })
            history.goBack()
          } else {
            handleEnviarFotoPerfil(response.data?.result?.id)
          }
        } else {
          notificacionWarning(response.data?.message)
        }
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleEnviarFotoPerfil = async (props) => {
    const formData = new FormData()
    formData.append("imgs", fotoPerfil?.file)
    try {
      const response = await axios.post(
        `colaboradores/foto-comercio/${colaborador.idComercio}/${props}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...colaborador, nombre: event.target.value }
    setColaborador(copyInput)
  }

  const handleChangeApellido = (event) => {
    let copyInput = { ...colaborador, apellido: event.target.value }
    setColaborador(copyInput)
  }

  const handleChangeCorreo = (event) => {
    let copyInput = { ...colaborador, email: event.target.value }
    setColaborador(copyInput)
  }

  const handleChangePassword = (event) => {
    let copyInput = { ...colaborador, password: event.target.value }
    setColaborador(copyInput)
  }

  const handleChangeNroContacto = (event) => {
    let copyInput = { ...colaborador, numeroContacto: event.target.value }
    setColaborador(copyInput)
  }

  const handleChangeNroDocumento = (event) => {
    let copyInput = { ...colaborador, documento: event.target.value }
    setColaborador(copyInput)
  }
  const handleChangeEsPropietario = (event) => {
    let copyInput = { ...colaborador, esPropietario: event.target.checked }
    setColaborador(copyInput)
  }

  const handleChangeEsDriver = (event) => {
    let copyInput = { ...colaborador, esDriver: event.target.checked }
    setColaborador(copyInput)
  }

  const handleEliminarImagen = (event) => {
    event.preventDefault()
    setFotoPerfil({})
  }

  return (
    <>
      <BackdropCustom open={isLoading} />
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              {Object.keys(fotoPerfil).length === 0 ? (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Avatar alt="Foto Perfil" style={{ width: 100, height: 100 }}>
                    <PhotoCameraIcon fontSize="large" />
                  </Avatar>
                </Box>
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => handleEliminarImagen(event)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      alt="Foto Perfil"
                      src={`data:${fotoPerfil?.type};base64,${fotoPerfil?.data}`}
                      style={{ width: 100, height: 100 }}
                    />
                  </Badge>
                </Box>
              )}

              <Upload {...uploaderProps}>
                <Button
                  size="small"
                  //  startIcon={<PhotoCameraIcon />}
                  //startIcon={PublishIcon}
                  variant="contained"
                  color="primary"
                  children="Foto perfil"
                />
              </Upload>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={colaborador.nombre}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="apellido"
                    name="apellido"
                    label="Apellido"
                    value={colaborador.apellido}
                    onChange={(value) => handleChangeApellido(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="correo"
                    name="correo"
                    label="Correo eléctronico"
                    value={colaborador.email}
                    onChange={(value) => handleChangeCorreo(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ paddingTop: 10 }}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      color="primary"
                      htmlFor="outlined-adornment-password"
                    >
                      Contraseña
                    </InputLabel>
                    <OutlinedInput
                      margin="dense"
                      color="primary"
                      required
                      size="small"
                      type={showPassword ? "text" : "password"}
                      value={colaborador.password}
                      onChange={(value) => handleChangePassword(value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="togglepassword"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={90}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="celular"
                    name="celular"
                    label="Celular"
                    value={colaborador.numeroContacto}
                    onChange={(value) => handleChangeNroContacto(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="tipoDocumento"
                    size="small"
                    value={tipoDocumento || ""}
                    onChange={onSelectTipoDocumento}
                    options={tipoDocumentoList?.content}
                    getOptionLabel={(option) =>
                      option.descripcion ? option.descripcion : ""
                    }
                    renderOption={(option) => (
                      <React.Fragment>{option?.descripcion}</React.Fragment>
                    )}
                    loading={isLoadingTipoDocumento}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tipo documento"
                        name="tipoDocumento"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingTipoDocumento ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="nroDocumento"
                    name="nroDocumento"
                    label="Nro. Documento"
                    value={colaborador.documento}
                    onChange={(value) => handleChangeNroDocumento(value)}
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={colaborador.esPropietario}
                          onChange={(value) => handleChangeEsPropietario(value)}
                          name="Propietario"
                          color="primary"
                        />
                      }
                      label="Propietario"
                    />
                  </FormGroup>
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={colaborador.esDriver}
                          onChange={(value) => handleChangeEsDriver(value)}
                          name="Driver"
                          color="primary"
                        />
                      }
                      label="Driver"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
