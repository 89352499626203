import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography } from "@material-ui/core"
import { alertWarningError } from "../../components/Notificaciones"
import Chip from "@material-ui/core/Chip"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import VisibilityIcon from "@material-ui/icons/Visibility"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import Alert from "@material-ui/lab/Alert"
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"

import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import EditIcon from "@material-ui/icons/Edit"

export default function ListarFormaDePago() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })
  const [state, setState] = useState({})

  useEffect(() => {
    getFormaPago()
  }, [])

  const getFormaPago = async (props) => {
    setIsLoading(true)
    let url = "configuraciones/tipo-pago"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const categ = response.data
        setData({ ...data, content: categ?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nueva-forma-de-pago")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "id_tp_pago",
      width: "1%",
      hidden: true,
    },
    {
      title: "Nombre",
      field: "nombre",
    },
    {
      title: "Estado",
      field: "estado",
      width: "15%",
      align: "center",
      render: (rowData) =>
        rowData.estado === "ACTIVO" ? (
          <Alert
            icon={false}
            variant="standard"
            severity="success"
            style={{ justifyContent: "center", padding: "0px 10px" }}
          >
            {rowData?.estado}
          </Alert>
        ) : (
          <Alert
            icon={false}
            variant="standard"
            severity="error"
            style={{ justifyContent: "center", padding: "0px 10px" }}
          >
            {rowData?.estado}
          </Alert>
        ),
    },
  ]
  const options = {
    filtering: false,
    exportButton: true,
    exportAllData: true,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const editar = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
  }

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar  ${props.data?.nombre}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        inactivar(props.data)
      }
    })
  }

  const inactivar = async (props) => {
    setIsLoading(true)
    let url = "configuraciones/tipo-pago-inactivar"
    try {
      const response = await axios.post(url, {
        idTipoPago: props?.id_tp_pago,
      })
      let status = response.status
      if (status === 200) {
        getFormaPago()
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDetalle = (event, props) => {
    event.stopPropagation()
    history.push("./detalle/", props.data)
  }
  const childrenAccions = (props) => {
    return (
      <>
        {props?.data?.estado === "ACTIVO" && (
          <>
            <Box pl={1} pr={1}>
              <Chip
                onClick={(e) => handleInactivar(e, props)}
                avatar={
                  <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                    <HighlightOffIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                  </Avatar>
                }
                label="Inactivar"
                variant="outlined"
                color="error"
              />
            </Box>
            <Box pl={1} pr={1}>
              <Chip
                onClick={(e) => history.push("./editar-forma-de-pago/", props.data)}
                avatar={
                  <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                    <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
                  </Avatar>
                }
                label="Editar"
                variant="outlined"
                color="secondary"
              />
            </Box>
          </>
        )}
      </>
    )
  }
  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              //  editar,
              childrenAccions,
            }}
          />
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
