import React, { useState, useEffect, useContext } from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Alert from "@material-ui/lab/Alert"
import TextField from "@material-ui/core/TextField"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardActionArea from "@material-ui/core/CardActionArea"
import { useStyles } from "../assets/styles/CustomStyles"
import TypographyBold from "../components/TypographyBold"
import { MakeTables } from "../components/MaterialTables/MakeTables"
import AddIcon from "@material-ui/icons/AddCircle"
import { useHistory } from "react-router-dom"
import VisibilityIcon from "@material-ui/icons/Visibility"
import UserContext from "../utils/user/UserContext"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import DashboardIcon from "@material-ui/icons/Dashboard"
import ReceiptIcon from "@material-ui/icons/Receipt"
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu"
import WorkOutlineIcon from "@material-ui/icons/WorkOutline"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import CloseIcon from "@material-ui/icons/Close"
import { red } from "@material-ui/core/colors"
import Chip from "@material-ui/core/Chip"
import axios from "../utils/axios"
import IconButton from "@material-ui/core/IconButton"

import { alertWarningError } from "../components/Notificaciones"
import BackdropCustom from "../components/BackdropCustom"
import StorefrontIcon from "@material-ui/icons/Storefront"
import PeopleIcon from "@material-ui/icons/People"
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import { queries } from "@testing-library/react"
import log from "eslint-plugin-react/lib/util/log"

const valueDefaultFiltro = {
  fechaDesde: "",
  fechaHasta: "",
}
export default function Tablero() {
  const classes = useStyles()
  const userContext = useContext(UserContext)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingComercio, setIsLoadingComercio] = useState(false)
  const [isLoadingPuntero, setIsLoadingPuntero] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  const [data, setData] = useState({
    ordenes: "",
    menus: "",
    comercios: "",
    comercioActivar: "",
    clientes: "",
  })
  const [listaComercio, setListaComercio] = useState({ content: [] })
  const [listaPuntero, setListaPuntero] = useState({ content: [] })
  const [filtroComercio, setFiltroComercio] = useState(valueDefaultFiltro)
  const [filtroPuntero, setFiltroPuntero] = useState(valueDefaultFiltro)

  const [quitarFitroComercio, setQuitarFiltroComercio] = useState(false)
  const [quitarFitroPuntero, setQuitarFiltroPuntero] = useState(false)

  useEffect(() => {
    setIsAdmin(userContext.state.esAdmin)
    if (userContext.state.exp) {
      // getData()
      getComercios()
      getPunteros()
    }
  }, [])

  const getComercios = async (props) => {
    setIsLoadingComercio(true)
    setQuitarFiltroComercio(false)
    let url = "commerce"
    try {
      const response = await axios.get(url, { params: { limit: 5, page: 1 } })
      let status = response.status
      if (status === 200) {
        setListaComercio({
          ...listaComercio,
          content: response.data,
        })
        setIsLoadingComercio(false)
      }
    } catch (error) {
      setIsLoadingComercio(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getPunteros = async (props) => {
    setIsLoadingPuntero(true)
    setQuitarFiltroPuntero(false)
    setFiltroPuntero(valueDefaultFiltro)
    let url = "interest/all-point-of-interest"
    try {
      const response = await axios.get(url, { params: { limit: 5, page: 1 } })
      let status = response.status
      if (status === 200) {
        const proyectos = response.data

        setListaPuntero({
          ...listaPuntero,

          content: proyectos,
        })
        setIsLoadingPuntero(false)
      }
    } catch (error) {
      setIsLoadingPuntero(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getFiltroComercio = async (props) => {
    setListaComercio({ ...listaComercio, content: [] })
    setIsLoading(true)
    setQuitarFiltroComercio(true)

    let url = "dashboard/comercio-listar"
    try {
      const response = await axios.post(url, filtroComercio)
      let status = response.status
      if (status === 200) {
        const filtroResponse = response.data
        setListaComercio({
          ...listaComercio,
          content: filtroResponse?.result,
        })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getFiltroPuntero = async (props) => {
    setListaPuntero({ ...listaPuntero, content: [] })
    setIsLoading(true)
    setQuitarFiltroPuntero(true)

    let url = "dashboard/puntero-listar"
    try {
      const response = await axios.post(url, filtroPuntero)
      let status = response.status
      if (status === 200) {
        const filtroResponse = response.data
        setListaPuntero({
          ...listaPuntero,
          content: filtroResponse?.result,
        })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <>
      <List>
        <ListItem>
          <ListItemText
            primary="TOP 5 DE COMERCIOS"
            secondary="Comercios con mayor facturación en el mes "
          />
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant="outlined"
            id="fecha"
            name="fecha"
            label="Desde"
            type="date"
            size="small"
            onChange={(e) => handleFechaDesdeComercio(e)}
            defaultValue={filtroComercio.fechaDesde}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant="outlined"
            id="fecha"
            name="fecha"
            label="Hasta"
            type="date"
            size="small"
            onChange={(e) => handleFechaHastaComercio(e)}
            defaultValue={filtroComercio.fechaHasta}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            //style={{ color: lightGreen[700] }}
            startIcon={<AddIcon />}
            onClick={() => getFiltroComercio()}
          >
            Filtrar
          </Button>
          {quitarFitroComercio && (
            <Tooltip title="Quitar filtros" arrow>
              <IconButton
                aria-label="detalle"
                size="small"
                className={classes.iconButton}
                onClick={() => {
                  getComercios()
                }}
              >
                <CloseIcon style={{ color: red[600] }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  )

  const titlePuntero = (
    <>
      <List>
        <ListItem>
          <ListItemText
            primary="GESTION DE PUNTERO"
            secondary="Detalle de gestión de puntos con comercios"
          />
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant="outlined"
            id="fecha"
            name="fecha"
            label="Desde"
            type="date"
            size="small"
            onChange={(e) => handleFechaDesdePuntero(e)}
            defaultValue={filtroPuntero.fechaDesde}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant="outlined"
            id="fecha"
            name="fecha"
            label="Hasta"
            type="date"
            size="small"
            onChange={(e) => handleFechaHastaPuntero(e)}
            defaultValue={filtroPuntero.fechaHasta}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            //style={{ color: lightGreen[700] }}
            startIcon={<AddIcon />}
            onClick={() => getFiltroPuntero()}
          >
            Filtrar
          </Button>
          {quitarFitroPuntero && (
            <Tooltip title="Quitar filtros" arrow>
              <IconButton
                aria-label="detalle"
                size="small"
                className={classes.iconButton}
                onClick={() => {
                  getPunteros()
                }}
              >
                <CloseIcon style={{ color: red[600] }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  )
  const columns = [
    {
      title: "ID",
      field: "id_cliente",
      width: "1%",
      hidden: true,
    },

    {
      title: "",
      field: "foto_princ",
      align: "center",
      render: (rowData) => (
        <img
          style={{ height: 30, borderRadius: "20%" }}
          src={`${rowData?.mainPicture}`}
          alt="Pendiente de Recarga"
        />
      ),
      width: "10%",
    },
    {
      title: "COMERCIO",
      field: "nombre",
      render: (rowData) => (
        <Typography
          variant="button"
          display="block"
          color="initial"
          //  style={{ color: "#ffffff" }}
        >
          {rowData?.name}
        </Typography>
      ),
      //  width: "15%",
    },

    {
      title: "ORDENES",
      field: "ordenes",
      width: "10%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={<ReceiptIcon fontSize="small" />}
          variant="outlined"
          severity="success"
          style={{ padding: "0px 10px" }}
        >
          {rowData?.orders}
        </Alert>
      ),
    },

    {
      title: "TOTAL",
      field: "totales",
      width: "20%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={<AccountBalanceIcon fontSize="small" />}
          variant="outlined"
          severity="warning"
          style={{ padding: "0px 10px" }}
        >
          {parseFloat(rowData?.total).toLocaleString("es")}
        </Alert>
      ),
    },
  ]

  const columnsPuntero = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true,
    },

    {
      title: "",
      field: "foto_princ",
      align: "center",
      render: (rowData) => (
        <img
          style={{ height: 30, borderRadius: "20%" }}
          src={`${rowData?.mainPicture}`}
          alt="Pendiente de Recarga"
          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
        />
      ),
      width: "10%",
    },

    {
      title: "Puntero",
      field: "name",
      render: (rowData) => <p>{rowData.punteroUser?.fullName}</p>,

      //  width: "15%",
    },

    {
      title: "Cargados",
      field: "concretados",
      width: "15%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={<StorefrontIcon fontSize="small" />}
          variant="standard"
          severity="success"
          style={{ justifyContent: "center", padding: "0px 10px" }}
        >
          {rowData?.concretados}
        </Alert>
      ),
    },

    {
      title: "Prospecto",
      field: "prospectos",
      width: "15%",
      align: "center",
      render: (rowData) => (
        <Alert
          icon={<WorkOutlineIcon fontSize="small" />}
          variant="standard"
          severity="info"
          style={{ justifyContent: "center", padding: "0px 10px" }}
        >
          {rowData?.prospectos}
        </Alert>
      ),
    },

    {
      title: "Visita total",
      field: "visita",
      width: "15%",
      align: "center",
    },
    {
      title: "Finalizada",
      field: "finalizada",
      width: "10%",
      align: "center",
    },
    {
      title: "Pendiente",
      field: "pendientes",
      width: "10%",
      align: "center",
    },
  ]

  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    search: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "75vh",
    paging: false,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const handleDetalle = (event, props) => {
    event.stopPropagation()
    history.push("./comercios/lista/detalle/", props.data.id)
  }

  const handleFechaDesdeComercio = (event) => {
    if (event.target.value === "") {
      let copyInput = { ...filtroComercio, fechaDesde: "" }
      setFiltroComercio(copyInput)
    } else {
      let copyInput = { ...filtroComercio, fechaDesde: event.target.value }
      setFiltroComercio(copyInput)
    }
  }

  const handleFechaHastaComercio = (event) => {
    if (event.target.value === "") {
      let copyInput = { ...filtroComercio, fechaHasta: "" }
      setFiltroComercio(copyInput)
    } else {
      let copyInput = { ...filtroComercio, fechaHasta: event.target.value }
      setFiltroComercio(copyInput)
    }
  }

  const handleFechaDesdePuntero = (event) => {
    if (event.target.value === "") {
      let copyInput = { ...filtroPuntero, fechaDesde: "" }
      setFiltroPuntero(copyInput)
    } else {
      let copyInput = { ...filtroPuntero, fechaDesde: event.target.value }
      setFiltroPuntero(copyInput)
    }
  }

  const handleFechaHastaPuntero = (event) => {
    if (event.target.value === "") {
      let copyInput = { ...filtroPuntero, fechaHasta: "" }
      setFiltroPuntero(copyInput)
    } else {
      let copyInput = { ...filtroPuntero, fechaHasta: event.target.value }
      setFiltroPuntero(copyInput)
    }
  }
  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circle">
                <VisibilityIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>
      </>
    )
  }
  return (
    <>
      <BackdropCustom open={isLoading} />

      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#f09f54" }}>
                  <DashboardIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Dashboard"
                secondary="Koki Administrativo Dashboard "
              />
            </ListItem>
          </List>
        </Grid>

        {/** datos del proyectos */}
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <MakeTables
            isLoading={isLoadingComercio}
            title={title}
            columns={columns}
            data={listaComercio.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
            }}
          />
        </Grid>

        {/** datos del proyecto */}
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Grid container spacing={6} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card
                style={{
                  boxShadow:
                    "0px 5px 1px -1px rgb(213 0 74 / 100%), 0px 1px 1px 0px rgb(213 0 74 / 14%), 0px 1px 8px 0px rgb(213 0 74 / 14%)",
                }}
              >
                <CardActionArea
                //onClick={() => history.push("./proyectos/")}
                >
                  <CardHeader
                    onClick={() => history.push("/gestionar-pedidos/lista-pedidos/")}
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <ReceiptIcon
                          style={{ color: "rgb(213 0 74 / 100%)", fontSize: "40px" }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                        //  style={{ color: "#ffffff" }}
                      >
                        PEDIDOS
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(213 0 74 / 100%)" }}
                      >
                        {data.ordenes}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card
                style={{
                  boxShadow:
                    "0px 5px 1px -1px rgb(2 81 18 / 100%), 0px 1px 1px 0px rgb(2 81 18 / 14%), 0px 1px 8px 0px rgb(2 81 18 / 14%)",
                }}
              >
                <CardActionArea
                //  onClick={() => history.push("./gestiones/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <RestaurantMenuIcon
                          style={{ color: "rgb(2 81 18 / 100%)", fontSize: "40px" }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                        //  style={{ color: "#ffffff" }}
                      >
                        MENU
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(2 81 18 / 100%)" }}
                      >
                        {data.menus}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card
                style={{
                  boxShadow:
                    "0px 5px 1px -1px rgb(233 156 34 / 100%), 0px 1px 1px 0px rgb(233 156 34 / 14%), 0px 1px 8px 0px rgb(233 156 34 / 14%)",
                }}
              >
                <CardActionArea
                  onClick={() => history.push("/comercios/lista-activado/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <StorefrontIcon
                          style={{
                            color: "rgb(233 156 34 / 100%)",
                            fontSize: "40px",
                          }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography variant="button" display="block" color="initial">
                        COMERCIOS
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(233 156 34 / 100%)" }}
                      >
                        {data.comercios}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card
                style={{
                  boxShadow:
                    "0px 5px 1px -1px rgb(219 83 34 / 100%), 0px 1px 1px 0px rgb(219 83 34 / 14%), 0px 1px 8px 0px rgb(219 83 34 / 14%)",
                }}
              >
                <CardActionArea
                  onClick={() => history.push("/comercios/lista-activar/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <StorefrontIcon
                          style={{
                            color: "rgb(219 83 34 / 100%)",
                            fontSize: "40px",
                          }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                        //  style={{ color: "#ffffff" }}
                      >
                        COMERCIOS P/ACTIVAR
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(219 83 34 / 100%)" }}
                      >
                        {data.comercioActivar}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card
                style={{
                  boxShadow:
                    "0px 5px 1px -1px rgb(25 117 228 / 100%), 0px 1px 1px 0px rgb(25 117 228 / 14%), 0px 1px 8px 0px rgb(25 117 228 / 14%)",
                }}
              >
                <CardActionArea
                //onClick={() => history.push("./tareas/")}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        variant="rounded"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <PeopleIcon
                          style={{
                            color: "rgb(25 117 228 / 100%)",
                            fontSize: "40px",
                          }}
                        />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="button"
                        display="block"
                        color="initial"
                        //  style={{ color: "#ffffff" }}
                      >
                        CLIENTES
                      </Typography>
                    }
                    subheader={
                      <TypographyBold
                        variant="h5"
                        display="block"
                        style={{ color: "rgb(25 117 228/ 100%)" }}
                      >
                        {data.clientes}
                      </TypographyBold>
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/** datos del proyectos */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MakeTables
            isLoading={isLoadingComercio}
            title={titlePuntero}
            columns={columnsPuntero}
            data={listaPuntero.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
