import React, { useEffect, useState, useContext } from "react"

import axios from "../../utils/axios"
import { alertWarningError } from "../../components/Notificaciones"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import { MapContainer, Tooltip, Marker, TileLayer } from "react-leaflet"
import { iconPerson } from "../../components/IconMap"
import { CardActions, Grid, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0") // Los meses son indexados desde 0
  const year = String(date.getFullYear()).slice(-2) // Obtener los últimos dos dígitos del año
  return `${day}/${month}/${year}`
}

export default function GetAllPuntoDeInteresMaps() {
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [puntodeInteres, setPuntodeInteres] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [punteroData, setPunteroData] = useState()
  const [data, setData] = useState({ content: [] })

  useEffect(() => {
    getPuntosDeInteres()
  }, [])

  const onSelectPuntero = (e, value) => {
    const selected = data.content.find((element) => element.id === value.id)

    setPunteroData(selected)
  }

  const getPuntosDeInteres = async () => {
    const url = "interest/all-point-of-interest"
    try {
      const responsePuntoInteres = await axios.get(url)
      const responseStatus = responsePuntoInteres.status
      if (responseStatus === 200) {
        const formattedData = responsePuntoInteres.data.map((item) => {
          const { lat, long } = item.latLong
          return {
            ...item,
            latitud: lat,
            longitud: long,
            fecha: formatDate(item.fecha),
          }
        })

        setPuntodeInteres(formattedData)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <Grid item sm={5} style={{ marginBottom: 10 }}>
            <Autocomplete
              id="tipoPuntero"
              size="small"
              value={puntodeInteres || ""}
              onChange={onSelectPuntero}
              options={data.content}
              getOptionLabel={(option) =>
                option.name ? `${puntodeInteres.name} ` : ""
              }
              renderOption={(option) => (
                <React.Fragment>{`${puntodeInteres.name}`}</React.Fragment>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleccione un puntero para el recorrido"
                  name="tipoPuntero"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>
          <MapContainer
            center={[-25.338719, -57.487774]}
            zoom={16}
            style={{ height: "800px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://carto.com/">CARTO</a>'
            />
            {puntodeInteres.length > 0 &&
              puntodeInteres.map((item, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: item.latitud, lng: item.longitud }}
                    data={item.id}
                    icon={iconPerson}
                  >
                    <Tooltip sticky>{item.nombre_del_punto}</Tooltip>
                  </Marker>
                )
              })}
          </MapContainer>
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
