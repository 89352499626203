import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Chip } from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import LinearProgress from "@material-ui/core/LinearProgress"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import { Grid, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import {
  pink,
  lightGreen,
  orange,
  deepPurple,
  indigo,
  cyan,
} from "@material-ui/core/colors"
import swal from "sweetalert"
import TypographyBold from "../../components/TypographyBold"
import TimerIcon from "@material-ui/icons/Timer"
import TodayIcon from "@material-ui/icons/Today"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import EventIcon from "@material-ui/icons/Event"
import EditIcon from "@material-ui/icons/Edit"
import CloseIcon from "@material-ui/icons/Close"

import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Avatar from "@material-ui/core/Avatar"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import HistoryIcon from "@material-ui/icons/History"
import CachedIcon from "@material-ui/icons/Cached"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import VisibilityIcon from "@material-ui/icons/Visibility"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

export default function LsitarEtapas() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    content: [],
    dashboard: {},
    usuarios: [],
  })

  useEffect(() => {
    getEtapas("listar")
  }, [])

  const getEtapas = async (props) => {
    setIsLoading(true)
    let url = "etapas/etapa-listar"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const etapa = response.data
        setData({
          ...data,
          content: etapa?.result,
        })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nueva-etapa/")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "idEtapa",
      width: "1%",
      hidden: true,
    },

    {
      title: "Nombre",
      field: "nombre",
      //  width: "15%",
    },

    {
      title: "Orden",
      field: "orden",
      width: "10%",
      render: (rowData) => (
        <Chip color="primary" label={rowData?.orden} size="small" />
      ),
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const handleDetalle = (event, props) => {
    event.stopPropagation()
    history.push("./detalle/", props.data)
  }

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar  ${props.data?.nombre}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        inactivar(props.data)
      }
    })
  }

  const inactivar = async (props) => {
    setIsLoading(true)
    let url = "etapas/etapa-inactivar"
    try {
      const response = await axios.post(url, {
        idEtapa: props?.idEtapa,
      })
      let status = response.status
      if (status === 200) {
        getEtapas("listar")
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circle">
                <VisibilityIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleInactivar(e, props)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <HighlightOffIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Inactivar"
            variant="outlined"
            color="error"
          />
        </Box>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => history.push("./editar/", props.data)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Editar"
            variant="outlined"
            color="secondary"
          />
        </Box>
      </>
    )
  }

  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <MakeTables
                isLoading={isLoading}
                title={title}
                columns={columns}
                data={data.content}
                actions={actions}
                classes={classes}
                options={options}
                componentsAssets={{
                  classes,
                  childrenAccions,
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
