import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { Copyright } from "../components/CopyRight"
import logo from "../assets/images/logo.png"
import { useForm } from "react-hook-form"
import { notificacionAlerta } from "../components/Notificaciones"
import TypographyBold from "../components/TypographyBold"

import { login } from "../utils/authUtils"
import { useStyles } from "../assets/styles/CustomStyles"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"

import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"

export default function Login() {
  const classes = useStyles()

  const { register, handleSubmit } = useForm()
  const [registrarse, setRegistrarse] = useState(false)

  const onsubmit = async (data, e) => {
    e.preventDefault()

    try {
      const response = await login(data)
      if (response.status === "OK") {
        window.location.href = "/"
      }
    } catch (error) {
      if (error) {
        notificacionAlerta(error)
      }
    }
  }

  const Login = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [data, setData] = useState({ email: "", password: "" })

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
      event.preventDefault()
    }

    const handleLogin = async (e) => {
      try {
        const response = await login(data)

        if (response.status === "OK") {
          window.location.href = "/"
        }
        if (response.status === "SINACCESO") {
          notificacionAlerta(response.message)
        }
      } catch (error) {
        if (error) {
          notificacionAlerta(error)
        }
      }
    }

    const handleEmail = (event) => {
      setData({ ...data, email: event.target.value })
    }

    const handlePassword = (event) => {
      setData({ ...data, password: event.target.value })
    }
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ justifyContent: "center", paddingBottom: 70 }}
        >
          <Grid item xs={11} sm={9} md={4} lg={4} xl={4}>
            <Box>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Box
                    p={5}
                    mb={3}
                    style={{ textAlign: "center", backgroundColor: "#F79C5F" }}
                  >
                    <img width="150px" alt="Logo" src={logo} />
                    <Box mt={3}>
                      <TypographyBold
                        variant="h5"
                        component="h2"
                        style={{ color: "#ffffff" }}
                      >
                        KOKI ADMINISTRATIVO
                      </TypographyBold>
                    </Box>
                  </Box>

                  <Box pt={3} pl={2} pr={2}>
                    <Box pb={5}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        value={data.email}
                        onChange={(value) => handleEmail(value)}
                        type="email"
                        fullWidth
                        color="primary"
                        label="Correo eléctronico"
                        autoFocus
                      />
                    </Box>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        color="primary"
                        htmlFor="outlined-adornment-password"
                      >
                        Contraseña
                      </InputLabel>
                      <OutlinedInput
                        color="primary"
                        required
                        type={showPassword ? "text" : "password"}
                        value={data.password}
                        onChange={(value) => handlePassword(value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="togglepassword"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={90}
                      />
                    </FormControl>
                  </Box>
                </CardContent>
                <CardActions>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ justifyContent: "center" }}
                  >
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleLogin()}
                      >
                        Acceder
                      </Button>
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                      <Button color="primary" fullWidth style={{ marginTop: 30 }}>
                        <TypographyBold
                          variant="body2"
                          display="block"
                          // color="textPrimary"
                        >
                          <Box fontSize="10" display="inline">
                            {" "}
                            RECUPERAR ACCESO
                          </Box>
                        </TypographyBold>
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }

  const handleOpenRegistrarse = () => {
    setRegistrarse(true)
  }

  const handleCloseRegistrarse = () => {
    setRegistrarse(false)
  }

  const Registrarse = () => {
    return (
      <>
        <div className={classes.paper}>
          <img width="300px" alt="Logo" src={logo} />

          <form id="login" onSubmit={handleSubmit(onsubmit)} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              color="secondary"
              label="Cedula"
              {...register("cedula", { required: true })}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              color="secondary"
              label="Correo"
              type="email"
              {...register("correo", { required: true })}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              color="secondary"
              fullWidth
              //   name="password"
              label="Contraseña"
              type="password"
              //   id="password"
              // inputRef={register({ required: true })}
              {...register("pass", { required: true })}
              autoComplete="current-password"
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.marginContent}
              type="submit"
            >
              REGISTRARME AHORA
            </Button>
          </form>
          <Button
            fullWidth
            color="secondary"
            className={classes.marginContent}
            onClick={() => handleCloseRegistrarse()}
          >
            LOGIN
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      {registrarse ? <Registrarse /> : <Login />}

      <Box className={classes.footerBotttom}>
        <Copyright />
      </Box>
    </>
  )
}
