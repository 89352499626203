import React, { useState, useEffect } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { alertWarningError } from "../../components/Notificaciones"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import BackdropCustom from "../../components/BackdropCustom"

const inicialValue = {
  image: null,
  description: "",
  order: 0,
  fromDate: "",
  toDate: "",
}

export default function EditarBanner() {
  const history = useHistory()
  const classes = useStyles()
  const dataProps = history.location.state

  const [isLoading, setIsLoading] = useState(false)
  const [banner, setBanner] = useState(inicialValue)
  const [bannerImage, setBannerImage] = useState({})
  const [fotoPrinc, setFotoPrinc] = useState({})
  const [rawImage, setRawImage] = useState({})

  useEffect(() => {
    let copyInput = {
      ...banner,
      id: dataProps?.id,
      description: dataProps?.description,
      order: dataProps?.order,
      fromDate: dataProps.fromDate,
      toDate: dataProps.toDate,
      image: dataProps.image,
    }
    setBanner(copyInput)
    setFotoPrinc(dataProps.image?.url)
  }, [])

  const handleFileChange = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]
    setRawImage(file)
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = function () {
      let base64 = reader.result.split(";base64,")
      let documentoBase64 = base64[1]
      let imagen = {
        file: file,
        type: file.type,
        name: file.name,
        data: documentoBase64,
      }
      setBannerImage(imagen)
    }
    setIsLoading(false)
    reader.onerror = function () {
      alert("No se pudo leer el archivo")
    }
  }

  const handleGuardar = async (props) => {
    if (isLoading) return
    setIsLoading(true)
    let fileResponse = null
    if (Object.keys(bannerImage).length !== 0) {
      const formData = new FormData()
      formData.append("file", rawImage, rawImage.name)
      fileResponse = await axios.post("/files/upload-image/image-mathi", formData)
    }

    try {
      const response = await axios.patch(`/banner/${banner.id}`, {
        ...banner,
        image: fileResponse?.data,
      })
      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        await swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...banner, description: event.target.value }
    setBanner(copyInput)
  }

  const handleChangeOrden = (event) => {
    let copyInput = { ...banner, order: event.target.value }
    setBanner(copyInput)
  }

  const handleChangeFechaDesde = (event) => {
    let copyInput = { ...banner, fromDate: event.target.value }
    setBanner(copyInput)
  }

  const handleChangeFechaHasta = (event) => {
    let copyInput = { ...banner, toDate: event.target.value }
    setBanner(copyInput)
  }

  const handleEliminarImagen = (event) => {
    event.preventDefault()
    setBannerImage({})
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={5}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              {fotoPrinc === null ? (
                Object.keys(bannerImage).length === 0 ? (
                  <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                    <Avatar alt="Foto Banner" style={{ width: 100, height: 100 }}>
                      <PhotoCameraIcon fontSize="large" />
                    </Avatar>
                  </Box>
                ) : (
                  <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <Tooltip title="Eliminar imagen" aria-label="eleminar">
                          <IconButton
                            edge="end"
                            variant="contained"
                            aria-label="delete"
                            onClick={(event) => handleEliminarImagen(event)}
                          >
                            <DeleteIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      }
                    >
                      <Avatar
                        variant="rounded"
                        alt="Foto Banner"
                        src={`data:${bannerImage?.type};base64,${bannerImage?.data}`}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Badge>
                  </Box>
                )
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => setFotoPrinc(null)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      variant="rounded"
                      alt="Foto categoría"
                      src={fotoPrinc}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Badge>
                </Box>
              )}

              <input
                type="file"
                accept=".png, .jpeg, .jpg"
                style={{ display: "none" }}
                id="upload-input"
                onChange={(event) => handleFileChange(event)}
              />

              <Button
                size="small"
                variant="contained"
                color="primary"
                children="Foto de Banner"
                onClick={() => document.getElementById("upload-input").click()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={banner.description}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={banner.order}
                    onChange={(value) => handleChangeOrden(value)}
                    variant="outlined"
                    id="Orden"
                    name="Orden"
                    label="Orden"
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={banner.fromDate}
                    onChange={(value) => handleChangeFechaDesde(value)}
                    variant="outlined"
                    id="FechaDesde"
                    name="FechaDesde"
                    label="Fecha Desde"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    value={banner.toDate}
                    onChange={(value) => handleChangeFechaHasta(value)}
                    variant="outlined"
                    id="FechaHasta"
                    name="FechaHasta"
                    label="Fecha Hasta"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
