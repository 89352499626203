import jwtDecode from "jwt-decode"

export const TOKEN_KEY = "token"

export const getUserPrincipal = () => {
  const jwtCookie = JSON.parse(window.localStorage.getItem(TOKEN_KEY))

  if (jwtCookie !== null) {
    const decodedAccessToken = jwtDecode(jwtCookie)
    return {
      idUsuario: decodedAccessToken?.idUsuario,
      nombre: decodedAccessToken?.nombre,
      apellido: decodedAccessToken?.apellido,
      urlFoto: jwtCookie?.user?.urlFoto,
      iat: decodedAccessToken?.iat,
      exp: decodedAccessToken?.exp,
    }
  }
}
export const logout = () => {
  localStorage.clear()
  window.localStorage.clear()
  var d = new Date()
  d.setTime(d.getTime())
  var expires = "expires=" + d.toUTCString()
  document.cookie =
    "jwt-pos= ; Path=/; expires=Tue, 06 Jul 2020 20:19:55 GMT;;domain=josu.com.py;"
  window.location.href = `/acceder`
}
