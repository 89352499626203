import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import UserContext from "../../utils/user/UserContext"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"

const initialValue = {
  name: "",
  nombreFantasia: "",
  cityId: 0,
  idBarrio: 0,
  mainAddress: "",
  secondaryAddress: "",
  contactNumer: "",
  clientType: "",
  deliveryType: "PROPIO",
  companyType: "SIN_RUC",
}

export default function NuevoComercio() {
  const history = useHistory()
  const classes = useStyles()
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCiudad, setIsLoadingCiudad] = useState(false)
  const [isLoadingBarrio, setIsLoadingBarrio] = useState(false)
  const [isLoadingTipoCliente, setIsLoadingTipoCliente] = useState(false)

  const [cliente, setCliente] = useState(initialValue)
  const [tipoCliente, setTipoCliente] = useState({})
  const [barrio, setBarrio] = useState({})
  const [barrioList, setBarrioList] = useState({ content: [] })
  const [ciudad, setCiudad] = useState({})
  const [ciudadList, setCiudadList] = useState({ content: [] })
  const [fotoPerfil, setFotoPerfil] = useState({})

  useEffect(() => {
    getCiudad()
  }, [])

  const getCiudad = async () => {
    setIsLoadingCiudad(true)
    let url = "address/get-all-cities"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const ciudad = response.data
        setCiudadList({ ...ciudadList, content: ciudad })

        setIsLoadingCiudad(false)
      }
    } catch (error) {
      setIsLoadingCiudad(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const getBarrio = async (props) => {
    setIsLoadingBarrio(true)
    let url = `address/get-all-neighbors-by-city-id/${props}`
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const barrio = response.data
        setBarrioList({ ...barrioList, content: barrio })

        setIsLoadingBarrio(false)
      }
    } catch (error) {
      setIsLoadingBarrio(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }
  const onSelectCiudad = (e, value) => {
    if (value && value?.id !== ciudad?.id) {
      setCiudad(value)
      let copyInput = { ...cliente, idCiudad: value?.id }
      setCliente(copyInput)
    }

    if (value === null) {
      setCiudad({})
      let copyInput = { ...cliente, idCiudad: 0 }
      setCliente(copyInput)
    }

    if (value?.id) {
      getBarrio(value?.id)
    } else {
      setCiudad({})
      setBarrio({})
      let copyInput = { ...cliente, idCiudad: 0, idBarrio: 0 }
      setCliente(copyInput)

      setBarrioList({ ...barrioList, content: [] })
    }
  }

  const onSelectBarrio = (e, value) => {
    if (value && value?.id !== barrio?.id) {
      setBarrio(value)

      let copyInput = { ...cliente, idBarrio: value?.id }
      setCliente(copyInput)
    }

    if (value === null) {
      let copyInput = { ...cliente, idBarrio: 0 }
      setCliente(copyInput)
      setBarrio({})
    }
  }

  const onSelectTipoCliente = (e, value) => {
    if (value && value !== tipoCliente) {
      setTipoCliente(value)
      let copyInput = { ...cliente, TipoCliente: value }
      setCliente(copyInput)
    }

    if (value === null) {
      setTipoCliente({})
      let copyInput = { ...cliente, TipoCliente: "" }
      setCliente(copyInput)
    }
  }

  const uploaderProps = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader()

      const duplicado = fotoPerfil?.file?.uid === file.uid

      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file)
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {
          let base64 = reader.result.split(";base64,")
          let documentoBase64 = base64[1]
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          }

          setFotoPerfil(imagen)
        }

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          alert("couldn't read the file")
        }
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      alert("onError", err)
    },
    capture: "josue",
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "commerce/create"
    try {
      const response = await axios.post(url, cliente)
      if (response.status === 200) {
        if (!fotoPerfil.file) {
          setIsLoading(false)
          swal("¡Operación Exitosa!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.goBack()
        } else {
          handleEnviarFotoPerfil(response.data?.result?.id)
        }
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const handleEnviarFotoPerfil = async (props) => {
    const formData = new FormData()
    formData.append("imgs", fotoPerfil?.file)
    try {
      const response = await axios.post(`comercios/foto/${props}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (e) => setCliente({ ...cliente, name: e.target.value })
  const handleChangeNombreFantasia = (e) =>
    setCliente({ ...cliente, nombreFantasia: e.target.value })
  const handleChangeCallePrincipal = (e) =>
    setCliente({ ...cliente, mainAddress: e.target.value })
  const handleChangeCalleSecundaria = (e) =>
    setCliente({ ...cliente, secondaryAddress: e.target.value })
  const handleChangeNumeroContacto = (e) =>
    setCliente({ ...cliente, contactNumer: e.target.value })
  const handleEliminarImagen = (e) => {
    e.preventDefault()
    setFotoPerfil({})
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              {Object.keys(fotoPerfil).length === 0 ? (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Avatar alt="Foto Perfil" style={{ width: 100, height: 100 }}>
                    <PhotoCameraIcon fontSize="large" />
                  </Avatar>
                </Box>
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => handleEliminarImagen(event)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      alt="Foto Perfil"
                      src={`data:${fotoPerfil?.type};base64,${fotoPerfil?.data}`}
                      style={{ width: 100, height: 100 }}
                    />
                  </Badge>
                </Box>
              )}

              <Upload {...uploaderProps}>
                <Button
                  size="small"
                  //  startIcon={<PhotoCameraIcon />}
                  //startIcon={PublishIcon}
                  variant="contained"
                  color="primary"
                  children="Foto perfil"
                />
              </Upload>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="name"
                    name="nombre"
                    label="Nombre"
                    value={cliente.name}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="tipoCliente"
                    size="small"
                    value={tipoCliente || ""}
                    onChange={onSelectTipoCliente}
                    options={["CLIENTE", "PROSPECTO", "PENDIENTE"]}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    loading={isLoadingTipoCliente}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tipo Cliente"
                        name="tipoCliente"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingTipoCliente ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="callePrincipal"
                    name="callePrincipal"
                    label="Calle principal"
                    value={cliente.mainAddress}
                    onChange={(value) => handleChangeCallePrincipal(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="calleSrincipal"
                    name="calleSrincipal"
                    label="Calle Secundaria"
                    value={cliente.secondaryAddress}
                    onChange={(value) => handleChangeCalleSecundaria(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="NroContacto"
                    name="NroContacto"
                    label="Nro Contacto"
                    value={cliente.contactNumer}
                    onChange={(value) => handleChangeNumeroContacto(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="ciudad"
                    size="small"
                    value={ciudad || ""}
                    onChange={onSelectCiudad}
                    options={ciudadList?.content}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option?.name}</React.Fragment>
                    )}
                    loading={isLoadingCiudad}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Ciudad"
                        name="ciudad"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingCiudad ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="barrio"
                    size="small"
                    value={barrio || ""}
                    onChange={onSelectBarrio}
                    options={barrioList?.content}
                    getOptionLabel={(option) => (option?.name ? option?.name : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option?.name}</React.Fragment>
                    )}
                    loading={isLoadingBarrio}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Barrio"
                        name="barrio"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingBarrio ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
