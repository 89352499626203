import axios from "axios"
import { notificacionAlerta } from "../components/Notificaciones"
import { TOKEN_KEY } from "./user/UserService"
export const origin = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}auth/login`

export const CancelToken = axios.CancelToken

const instance = axios.create({
  baseURL: origin,
  timeout: 10000,
})

export const login = async (datosUser) => {
  try {
    axios.defaults.headers.common["Authorization"] = ""
    const response = await instance.post("/", datosUser)
    if (response?.data.user.role === "SUPERADMIN") {
      window.localStorage.setItem(
        TOKEN_KEY,
        JSON.stringify(response?.data.accessToken),
      )

      axios.defaults.headers.common["Authorization"] =
        `Bearer ${response?.data.accessToken}`
      return { status: "OK", response: response?.data }
    } else {
      return {
        status: "SINACCESO",
        message: "No cuentas con permiso de Administrador",
      }
    }
  } catch (error) {
    if (error.message) {
      notificacionAlerta(error.response?.data?.message)
    }
    return { status: "ERR", message: error }
  }
}

export default instance
