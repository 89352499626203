import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography, Chip } from "@material-ui/core"
import {
  alertWarningError,
  notificacionEliminar,
} from "../../components/Notificaciones"
import Switch from "@material-ui/core/Switch"
import Box from "@material-ui/core/Box"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import LinearProgress from "@material-ui/core/LinearProgress"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import { Grid, Avatar } from "@material-ui/core"
import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import TypographyBold from "../../components/TypographyBold"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"

export default function ListarComercioSinCarteras() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCartera, setIsLoadingCartera] = useState(false)
  const [cliente, setCliente] = useState({})
  const [carteraList, setCarteraList] = useState({ content: [] })
  const [open, setOpen] = useState(false)
  const [openLoad, setOpenLoad] = useState(false)

  const [data, setData] = useState({ content: [] })

  useEffect(() => {
    getSinCartera()
    getCartera()
  }, [])

  const getSinCartera = async (props) => {
    setIsLoading(true)
    let url = "carteras/listar-clientes/"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const sinCartera = response.data

        setData({ ...data, content: sinCartera?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const getCartera = async (props) => {
    setIsLoadingCartera(true)
    let url = "carteras/listar-cartera/"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const carteras = response.data
        setCarteraList({ ...carteraList, content: carteras?.result })

        setIsLoadingCartera(false)
      }
    } catch (error) {
      setIsLoadingCartera(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<BusinessCenterIcon />}
      onClick={() => history.push("../asignar-cartera")}
    >
      Asignar cartera
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true,
    },

    {
      title: "Cliente",
      field: "nombre",
      //width: "25%",
    },
  ]
  const options = {
    filtering: false,
    exportButton: false,
    exportAllData: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    showTitle: false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const editar = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
  }

  const eliminarTipoColaborador = async (props) => {
    setIsLoading(true)
    let url = "tipos/inactivar-tipo-usuario/" + props?.id_tp_usuario_crm
    try {
      const response = await axios.put(url)
      let status = response.status
      if (status === 200) {
        getSinCartera()
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const eliminar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres Inactivar a ${props.data?.cliente}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        eliminarTipoColaborador(props.data)
      }
    })
  }

  const detalle = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
  }
  const handleAsingar = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
    setOpen(true)
    setCliente(props.data)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenLoad(false)
  }

  function AsignarCartera(props) {
    const { open, onClose, data } = props
    const [cartera, setCartera] = useState({})

    const onSelectCartera = (e, value) => {
      if (value && value?.id !== cartera?.id) {
        setCartera(value)
      }

      if (value === null) {
        setCartera({})
      }
    }

    function Loaders() {
      return <LinearProgress />
    }

    const handleGuardar = async () => {
      setIsLoading(true)
      onClose()
      let url = "carteras/nueva-cartera-detalle"

      let newObject = {
        idCartera: cartera?.id,
        idCliente: data?.id,
      }

      try {
        const response = await axios.post(url, newObject)
        let status = response.status
        if (status === 201) {
          setIsLoading(false)
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.go(0)
        }
      } catch (error) {
        onClose()
        setIsLoading(false)
        if (error.response) {
          alertWarningError(error.response)
        }
      }
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
          ASIGNAR CLIENTE A CARTERA
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                CLIENTE SELECCIONADO
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Chip
                  label={data?.cliente}
                  variant="default"
                  color="primary"
                  icon={<AssignmentIndIcon />}
                />
              </Box>
            </Grid>

            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Typography variant="body1" display="block" color="textSecondary">
                SELECCIONAR CARTERA
              </Typography>
              <Box ml={1} mt={1} mb={1}>
                <Autocomplete
                  id="idCartera"
                  onChange={onSelectCartera}
                  size="small"
                  value={cartera || ""}
                  loading={isLoadingCartera}
                  options={carteraList.content}
                  getOptionLabel={(option) => (option.cartera ? option.cartera : "")}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="cartera"
                      label="Cartera"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingCartera ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  onClose()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                disabled={Object.keys(cartera).length === 0 ? true : false}
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </DialogActions>
        {openLoad && <Loaders />}
      </Dialog>
    )
  }
  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleAsingar(e, props)}
            avatar={
              <Avatar variant="circle">
                <BusinessCenterIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Asignar a cartera"
            variant="outlined"
            color="primary"
          />
        </Box>
      </>
    )
  }
  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              //editar,
              //eliminar,
              childrenAccions,
            }}
          />
          {open && (
            <AsignarCartera open={open} onClose={handleClose} data={cliente} />
          )}
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
