import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import UserContext from "../../utils/user/UserContext"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import BackdropCustom from "../../components/BackdropCustom"

const inicialValue = {
  id: 0,
  name: "",
  mainAddress: "",
  secondaryAddress: "",
  city: "",
  neighborhood: "",
  latLong: "",
  owner: "",
  socialReason: "",
  ruc: null,
  contactNumer: "",
  whatsapp: "",
  employeesQty: 0,
  hasDelivery: "SI",
  deliveryType: "",
  partnerType: "",
  facebook: "",
  instagram: "",
  tiktok: "",
  nisAnde: "",
  waterCompany: "",
  companyType: "",
  yearOld: "",
  monthOld: "",
  loan: false,
  neededProducts: "",
  iva: false,
  ivaYear: 0,
  ivaMonth: 0,
  patent: false,
  patentYear: 0,
  patentMonth: 0,
  canon: false,
  canonYear: 0,
  canonMonth: 0,
  scoring: "",
  formStatus: "",
  mainPicture: null,
}

export default function EditarComercio() {
  const history = useHistory()
  const classes = useStyles()
  const dataProps = history.location.state
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCiudad, setIsLoadingCiudad] = useState(false)
  const [isLoadingBarrio, setIsLoadingBarrio] = useState(false)

  const [cliente, setCliente] = useState(inicialValue)
  const [tipoEmpresa, setTipoEmpresa] = useState({})
  const [tipoDelivery, setTipoDelivery] = useState({})
  const [tieneDelivery, setTieneDelivery] = useState({})
  const [estadoFormulario, setEstadoFormulario] = useState({})
  const [barrio, setBarrio] = useState({})
  const [barrioList, setBarrioList] = useState({ content: [] })
  const [ciudad, setCiudad] = useState({})
  const [ciudadList, setCiudadList] = useState({ content: [] })
  const [fotoPrinc, setFotoPrinc] = useState({})
  const [commerceImage, setCommerceImage] = useState({})
  const [rawImage, setRawImage] = useState({})
  // const [latitud, setLatitud] = useState()
  // const [longitud, setLongitud] = useState()

  useEffect(() => {
    console.log(dataProps.city)
    if (dataProps?.id > 0) {
      getCiudad()
      setCliente((prevState) => ({
        ...prevState,
        id: dataProps?.id,
        name: dataProps?.name,
        mainAddress: dataProps?.mainAddress,
        secondaryAddress: dataProps?.secondaryAddress,
        city: dataProps?.city,
        neighborhood: dataProps?.neighborhood,
        latLong: dataProps?.latLong,
        owner: dataProps?.owner,
        socialReason: dataProps?.ruc?.socialReason,
        ruc: dataProps?.ruc?.ruc,
        contactNumer: dataProps?.contactNumer,
        whatsapp: dataProps?.whatsapp,
        employeesQty: dataProps?.employeesQty,
        hasDelivery: dataProps?.hasDelivery ? "SI" : "NO",
        deliveryType: dataProps?.deliveryType,
        partnerType: dataProps?.partnerType,
        facebook: dataProps?.facebook,
        instagram: dataProps?.instagram,
        tiktok: dataProps?.tiktok,
        andeNIS: dataProps?.andeNIS,
        waterCompany: dataProps?.waterCompany,
        companyType: dataProps?.companyType,
        yearOld: dataProps?.yearOld,
        monthOld: dataProps?.monthOld,
        loan: dataProps?.loan,
        neededProducts: dataProps?.neededProducts,
        iva: dataProps?.iva,
        ivaYear: dataProps?.ivaYear,
        ivaMonth: dataProps?.ivaMonth,
        patent: dataProps?.patent,
        patentYear: dataProps?.patentYear,
        patentMonth: dataProps?.patentMonth,
        canon: dataProps?.canon,
        canonYear: dataProps?.canonYear,
        canonMonth: dataProps?.canonMonth,
        scoring: dataProps?.scoring,
        formStatus: dataProps?.formState,
        mainPicture: dataProps?.mainPicture,
      }))
      setCommerceImage({})
      setTipoEmpresa(dataProps?.tipoEmpresa)
      setTipoDelivery(dataProps?.tipoDelivery)
      setTieneDelivery(dataProps?.tieneDelivery)
      setEstadoFormulario(dataProps?.estadoFormulario)
      setFotoPrinc(dataProps.mainPicture?.url)
      // setLatitud(dataProps.latLong?.lat)
      // setLongitud(dataProps.latLong?.long)

      setBarrio({
        id_ciudad: dataProps?.idCiudad,
        id_barrio: dataProps?.idBarrio,
        nombre: dataProps?.barrio,
      })
    }
  }, [])

  const getCiudad = async () => {
    setIsLoadingCiudad(true)
    let url = "address/get-all-cities"
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const ciudad = response.data
        setCiudadList({ ...ciudadList, content: ciudad })

        setIsLoadingCiudad(false)
      }
    } catch (error) {
      setIsLoadingCiudad(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const getBarrio = async (props) => {
    setIsLoadingBarrio(true)
    let url = `address/get-all-neighbors-by-city-id/${props}`
    try {
      const response = await axios.get(url)
      let status = response.status
      if (status === 200) {
        const barrio = response.data
        setBarrioList({ ...barrioList, content: barrio })

        setIsLoadingBarrio(false)
      }
    } catch (error) {
      setIsLoadingBarrio(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const onSelectCiudad = (e, value) => {
    if (value && value?.id !== ciudad?.id) {
      setCiudad(value)
      let copyInput = { ...cliente, city: value?.name }
      setCliente(copyInput)
    }

    if (value === null) {
      setCiudad({})
      let copyInput = { ...cliente, idCiudad: 0 }
      setCliente(copyInput)
    }

    if (value?.id) {
      getBarrio(value?.id)
    } else {
      setCiudad({})
      setBarrio({})
      let copyInput = { ...cliente, idCiudad: 0, idBarrio: 0 }
      setCliente(copyInput)

      setBarrioList({ ...barrioList, content: [] })
    }
  }

  const onSelectBarrio = (e, value) => {
    if (value && value?.id !== barrio?.id) {
      setBarrio(value)

      let copyInput = { ...cliente, neighborhood: value?.name }
      setCliente(copyInput)
    }

    if (value === null) {
      let copyInput = { ...cliente, idBarrio: 0 }
      setCliente(copyInput)
      setBarrio({})
    }
  }

  // const onSelectCiudad = (event) => {
  //   setCiudad(event.target.innerHTML)
  //   // let copyInput = { ...cliente, hasDelivery: event.target.innerHTML }
  //   // setCliente(copyInput)
  // }

  // const onSelectBarrio = (event) => {
  //   setBarrio(event.target.innerHTML)
  //   // let copyInput = { ...cliente, hasDelivery: event.target.innerHTML }
  //   // setCliente(copyInput)
  // }

  const onSelectTipoEmpresa = (event) => {
    let copyInput = { ...cliente, companyType: event.target.innerHTML }
    setCliente(copyInput)
  }

  const onSelectEstadoFormulario = (event) => {
    let copyInput = { ...cliente, formStatus: event.target.innerHTML }
    setCliente(copyInput)
  }

  const handleFileChange = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]
    setRawImage(file)
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = function () {
      let base64 = reader.result.split(";base64,")
      let documentoBase64 = base64[1]
      let imagen = {
        file: file,
        type: file.type,
        name: file.name,
        data: documentoBase64,
      }
      setCommerceImage(imagen)
    }
    setIsLoading(false)
    reader.onerror = function () {
      alert("No se pudo leer el archivo")
    }
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    const rucData = {
      ruc: cliente.ruc,
      socialReason: cliente.socialReason,
      default: false,
      status: "ACTIVO",
    }
    let url = "commerce/update"
    // const laslons = { lat: Number(latitud.latitud), long: Number(longitud.longitud) }
    let fileResponse = null
    if (Object.keys(rawImage).length !== 0) {
      const formData = new FormData()
      formData.append("file", rawImage, rawImage.name)
      fileResponse = await axios.post("/files/upload-image/comercio", formData)
    }

    try {
      const response = await axios.patch(
        url,
        {
          ...cliente,
          mainPicture: fileResponse?.data,
          ruc: rucData,
        },
        { params: { commerceId: cliente.id } },
      )
      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...cliente, name: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeCallePrincipal = (event) => {
    let copyInput = { ...cliente, mainAddress: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeCalleSecundaria = (event) => {
    let copyInput = { ...cliente, secondaryAddress: event.target.value }
    setCliente(copyInput)
  }

  // const handleChangeLatitud = (event) => {
  //   let copyInput = { ...latitud, latitud: event.target.value }
  //   setLatitud(copyInput)
  // }
  // const handleChangeLongitud = (event) => {
  //   let copyInput = { ...longitud, longitud: event.target.value }
  //   setLongitud(copyInput)
  // }
  const handleChangeNombrePropietario = (event) => {
    let copyInput = { ...cliente, owner: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeRazonSocial = (event) => {
    let copyInput = { ...cliente, socialReason: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeRUC = (event) => {
    let copyInput = { ...cliente, ruc: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeNumeroContacto = (event) => {
    let copyInput = { ...cliente, contactNumer: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeWhatsapp = (event) => {
    let copyInput = { ...cliente, whatsapp: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeCantidadPersonal = (event) => {
    let copyInput = { ...cliente, employeesQty: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangeTieneDelivery = (event) => {
    let copyInput = { ...cliente, hasDelivery: event.target.innerHTML }
    setCliente(copyInput)
  }

  const handleChangeTipoDelivery = (event) => {
    let copyInput = { ...cliente, deliveryType: event.target.innerHTML }
    setCliente(copyInput)
  }
  const handleChangeTipoPartner = (event) => {
    let copyInput = { ...cliente, partnerType: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeFacebook = (event) => {
    let copyInput = { ...cliente, facebook: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeInstagram = (event) => {
    let copyInput = { ...cliente, instagram: event.target.value }
    setCliente(copyInput)
  }
  const handleChangetiktok = (event) => {
    let copyInput = { ...cliente, tiktok: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeNis = (event) => {
    let copyInput = { ...cliente, andeNIS: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeServicioAguateria = (event) => {
    let copyInput = { ...cliente, waterCompany: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeAntiguedadAnio = (event) => {
    let copyInput = { ...cliente, yearOld: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangeAntiguedadMes = (event) => {
    let copyInput = { ...cliente, monthOld: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangePrestamo = (event) => {
    let copyInput = { ...cliente, loan: event.target.checked }
    setCliente(copyInput)
  }
  const handleChangeProductoNecesita = (event) => {
    let copyInput = { ...cliente, neededProducts: event.target.value }
    setCliente(copyInput)
  }

  const handleChangeIVA = (event) => {
    let copyInput = { ...cliente, iva: event.target.checked }
    setCliente(copyInput)
  }

  const handleChangeIvaAnio = (event) => {
    let copyInput = { ...cliente, ivaYear: Number(event.target.value) }
    setCliente(copyInput)
  }
  const handleChangeIvaMes = (event) => {
    let copyInput = { ...cliente, ivaMonth: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangePatente = (event) => {
    let copyInput = { ...cliente, patent: event.target.checked }
    setCliente(copyInput)
  }

  const handleChangePatenteAnio = (event) => {
    let copyInput = { ...cliente, patentYear: Number(event.target.value) }
    setCliente(copyInput)
  }
  const handleChangePatenteMes = (event) => {
    let copyInput = { ...cliente, patentMonth: Number(event.target.value) }
    setCliente(copyInput)
  }

  const handleChangeCanon = (event) => {
    let copyInput = { ...cliente, canon: event.target.checked }
    setCliente(copyInput)
  }

  const handleChangeCanonAnio = (event) => {
    let copyInput = { ...cliente, canonYear: Number(event.target.value) }
    setCliente(copyInput)
  }
  const handleChangeCanonMes = (event) => {
    let copyInput = { ...cliente, canonMonth: Number(event.target.value) }
    setCliente(copyInput)
  }
  const handleChangeDatoPropietario = (event) => {
    let copyInput = { ...cliente, datosPropietario: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeDatoEmpleado = (event) => {
    let copyInput = { ...cliente, datosEmpleado: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeScoring = (event) => {
    let copyInput = { ...cliente, scoring: event.target.value }
    setCliente(copyInput)
  }
  const handleChangeEstadoFormulario = (event) => {
    let copyInput = { ...cliente, formState: event.target.value }
    setCliente(copyInput)
  }

  const handleEliminarImagen = (event) => {
    event.preventDefault()
    setCommerceImage({})
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardContent>
          <Grid container direction="row" justify="flex-start" spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              style={{ textAlign: "center", paddingTop: 30 }}
            >
              {fotoPrinc === null ? (
                Object.keys(commerceImage).length === 0 ? (
                  <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                    <Avatar alt="Foto Banner" style={{ width: 100, height: 100 }}>
                      <PhotoCameraIcon fontSize="large" />
                    </Avatar>
                  </Box>
                ) : (
                  <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <Tooltip title="Eliminar imagen" aria-label="eleminar">
                          <IconButton
                            edge="end"
                            variant="contained"
                            aria-label="delete"
                            onClick={(event) => handleEliminarImagen(event)}
                          >
                            <DeleteIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      }
                    >
                      <Avatar
                        variant="rounded"
                        alt="Foto Banner"
                        src={`data:${commerceImage?.type};base64,${commerceImage?.data}`}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Badge>
                  </Box>
                )
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => setFotoPrinc(null)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      variant="rounded"
                      alt="Foto categoría"
                      src={fotoPrinc}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Badge>
                </Box>
              )}

              <input
                type="file"
                accept=".png, .jpeg, .jpg"
                style={{ display: "none" }}
                id="upload-input"
                onChange={(event) => handleFileChange(event)}
              />
              <Button
                size="small"
                //  startIcon={<PhotoCameraIcon />}
                //startIcon={PublishIcon}
                variant="contained"
                color="primary"
                children="Foto perfil"
                onClick={() => document.getElementById("upload-input").click()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="name"
                    name="nombre"
                    label="Nombre"
                    value={cliente.name}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="callePrincipal"
                    name="callePrincipal"
                    label="Calle principal"
                    value={cliente.mainAddress}
                    onChange={(value) => handleChangeCallePrincipal(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="calleSrincipal"
                    name="calleSrincipal"
                    label="Calle Secundaria"
                    value={cliente.secondaryAddress}
                    onChange={(value) => handleChangeCalleSecundaria(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="ciudad"
                    size="small"
                    value={ciudad || ""}
                    onChange={onSelectCiudad}
                    options={ciudadList?.content}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option?.name}</React.Fragment>
                    )}
                    loading={isLoadingCiudad}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={cliente.city}
                        name="ciudad"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingCiudad ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="barrio"
                    size="small"
                    value={barrio || ""}
                    onChange={onSelectBarrio}
                    options={barrioList?.content}
                    getOptionLabel={(option) => (option?.name ? option?.name : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option?.name}</React.Fragment>
                    )}
                    loading={isLoadingBarrio}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={cliente.neighborhood}
                        name="barrio"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingBarrio ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    value={cliente.latLong?.lat || ""}
                    onChange={(value) => handleChangeLatitud(value)}
                    variant="outlined"
                    id="latitud"
                    name="latitud"
                    label="Latitud"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    value={cliente.latLong?.long || ""}
                    onChange={(value) => handleChangeLongitud(value)}
                    variant="outlined"
                    id="longitud"
                    name="longitud"
                    label="Longitud"
                    type="text"
                    fullWidth
                  />
                </Grid> */}
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    value={cliente.owner}
                    onChange={(value) => handleChangeNombrePropietario(value)}
                    variant="outlined"
                    id="propietario"
                    name="propietario"
                    label="Nombre propietario"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    value={cliente.socialReason}
                    onChange={(value) => handleChangeRazonSocial(value)}
                    variant="outlined"
                    id="razonSocial"
                    name="razonSocial"
                    label="Razón Social"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    value={cliente.ruc}
                    onChange={(value) => handleChangeRUC(value)}
                    variant="outlined"
                    id="ruc"
                    name="ruc"
                    label="RUC"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="NroContacto"
                    name="NroContacto"
                    label="Nro Contacto"
                    value={cliente.contactNumer}
                    onChange={(value) => handleChangeNumeroContacto(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="Whatsapp"
                    name="Whatsapp"
                    label="Whatsapp"
                    value={cliente.whatsapp}
                    onChange={(value) => handleChangeWhatsapp(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="cantidadPersonal"
                    name="cantidadPersonal"
                    label="Cantidad Personal"
                    value={cliente.employeesQty}
                    onChange={(value) => handleChangeCantidadPersonal(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    id="hasDelivery"
                    size="small"
                    value={cliente.hasDelivery}
                    onChange={(value) => handleChangeTieneDelivery(value)}
                    options={["SI", "NO"]}
                    getOptionLabel={(option) => option ?? ""}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tiene Delivery"
                        name="hasDelivery"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    id="tipoDelivery"
                    size="small"
                    value={cliente.deliveryType}
                    onChange={(value) => handleChangeTipoDelivery(value)}
                    options={["PROPIO", "TERCERIZADO"]}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tipo Delivery"
                        name="tipoDelivery"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="tipoPartner"
                    name="tipoPartner"
                    label="Tipo Partner"
                    value={cliente.partnerType}
                    onChange={(value) => handleChangeTipoPartner(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="facebook"
                    name="facebook"
                    label="Facebook"
                    value={cliente.facebook}
                    onChange={(value) => handleChangeFacebook(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="instagram"
                    name="instagram"
                    label="Instagram"
                    value={cliente.instagram}
                    onChange={(value) => handleChangeInstagram(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="tiktok"
                    name="Tiktok"
                    label="Tiktok"
                    value={cliente.tiktok}
                    onChange={(value) => handleChangetiktok(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="nisAnde"
                    name="nisAnde"
                    label="NIS ANDE"
                    value={cliente.andeNIS}
                    onChange={(value) => handleChangeNis(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="servicioAguateria"
                    name="servicioAguateria"
                    label="Servicio Aguateria"
                    value={cliente.waterCompany}
                    onChange={(value) => handleChangeServicioAguateria(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    id="tipoEmpresa"
                    size="small"
                    value={cliente.companyType || ""}
                    onChange={(value) => onSelectTipoEmpresa(value)}
                    options={["UNIPERSONAL", "SRL", "SA", "SIN_RUC"]}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tipo empresa"
                        name="tipoEmpresa"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="antiguedadAnho"
                    name="antiguedadAnho"
                    label="Antiguedad año"
                    value={cliente.yearOld}
                    onChange={(value) => handleChangeAntiguedadAnio(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="antiguedadMes"
                    name="antiguedadMes"
                    label="Antiguedad mes"
                    value={cliente.monthOld}
                    onChange={(value) => handleChangeAntiguedadMes(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cliente.loan}
                          onChange={(value) => handleChangePrestamo(value)}
                          name="Prestamo"
                          color="primary"
                        />
                      }
                      label="Prestamo"
                    />
                  </FormGroup>
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="productoNecesita"
                    name="productoNecesita"
                    label="Producto Necesita"
                    value={cliente.neededProducts}
                    onChange={(value) => handleChangeProductoNecesita(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cliente.iva}
                          onChange={(value) => handleChangeIVA(value)}
                          name="IVA"
                          color="primary"
                        />
                      }
                      label="IVA"
                    />
                  </FormGroup>
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="ivaMes"
                    name="ivaMes"
                    label="IVA mes"
                    value={cliente.ivaMonth}
                    onChange={(value) => handleChangeIvaMes(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="ivaAnho"
                    name="ivaAnho"
                    label="IVA año"
                    value={cliente.ivaYear}
                    onChange={(value) => handleChangeIvaAnio(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cliente.patent}
                          onChange={(value) => handleChangePatente(value)}
                          name="Patente"
                          color="primary"
                        />
                      }
                      label="Patente"
                    />
                  </FormGroup>
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="patenteMes"
                    name="patenteMes"
                    label="Patente mes"
                    value={cliente.patentMonth}
                    onChange={(value) => handleChangePatenteMes(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="patenteAnho"
                    name="patenteAnho"
                    label="Patente año"
                    value={cliente.patentYear}
                    onChange={(value) => handleChangePatenteAnio(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cliente.canon}
                          onChange={(value) => handleChangeCanon(value)}
                          name="Canon"
                          color="primary"
                        />
                      }
                      label="Canon"
                    />
                  </FormGroup>
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="canonMes"
                    name="canonMes"
                    label="Canon mes"
                    value={cliente.canonMonth}
                    onChange={(value) => handleChangeCanonMes(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="canonAnho"
                    name="canonAnho"
                    label="Canon año"
                    value={cliente.canonYear}
                    onChange={(value) => handleChangeCanonAnio(value)}
                    type="number"
                    fullWidth
                  />
                </Grid>
                {/* <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="datosPropietario"
                    name="datosPropietario"
                    label="Datos Propietario"
                    value={cliente.owner}
                    onChange={(value) => handleChangeDatoPropietario(value)}
                    type="text"
                    fullWidth
                  />
                </Grid> */}

                {/* <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="datosEmpleado"
                    name="datosEmpleado"
                    label="Datos Empleado"
                    value={cliente.datosEmpleado}
                    onChange={(value) => handleChangeDatoEmpleado(value)}
                    type="text"
                    fullWidth
                  />
                </Grid> */}
                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="scoring"
                    name="scoring"
                    label="Scoring"
                    value={cliente.scoring}
                    onChange={(value) => handleChangeScoring(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    id="estadoFormulario"
                    size="small"
                    value={cliente.formStatus || ""}
                    onChange={(value) => onSelectEstadoFormulario(value)}
                    options={["ACTIVATE", "ACTIVE", "PENDING", "MAINTENANCE"]}
                    getOptionLabel={(option) => (option ? option : "")}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Estado Formulario"
                        name="estadoFormulario"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
