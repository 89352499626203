import React, { useEffect, useState } from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { alertWarningError } from "../../components/Notificaciones"
import axios from "../../utils/axios"
import { BotonGris, BotonVerde } from "../../assets/styles/StyledButtons"
import SaveIcon from "@material-ui/icons/Save"
import { useHistory } from "react-router-dom"
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet"
import { iconPerson } from "../../components/IconMap"
import Typography from "@material-ui/core/Typography"
import swal from "sweetalert"

const pointDTO = {
  name: "",
  latLong: null,
  interestFamily: null,
  interestType: null,
  interestSubType: null,
  data: {},
  punteroUser: null,
  comments: "",
  mainAddress: "",
  secondaryAddress: "",
  department: "",
  neighborhood: "",
  city: "",
  houseNumber: null,
  status: "",
  id: "",
  fechaDeActualizacion: "",
}

export default function EditarPuntoDeInteres() {
  const history = useHistory()
  const props = history.location.state

  const [status] = useState({
    content: [
      { status: "ACEPTADO" },
      { status: "PENDIENTE" },
      { status: "RECHAZADO" },
      { status: "BORRADOR" },
    ],
  })
  const [data, setData] = useState(pointDTO)
  const [familia, setFamilia] = useState({ content: [] })
  const [tipo, setTipo] = useState({ content: [] })
  const [subTipo, setSubTipo] = useState({ content: [] })
  const [grupoDeInformacion, setGrupoDeInformacion] = useState({ content: [] })
  const [informacionMeta, setInformacionMeta] = useState({ content: [] })
  const [familiaSeleccionada, setFamiliaSeleccionada] = useState(Number)
  const [tipoSeleccionado, setTipoSeleccionado] = useState(Number)
  const [subTipoSeleccionado, setSubTipoSeleccionado] = useState(Number)
  const [googleApiResponse, setGoogleApiResponse] = useState()
  const [grupoDeInformacionSeleccionado] = useState()

  const [mapClicked, setMapClicked] = useState(false)

  // Init
  const initialGlobalState = () => {
    setData((prevState) => ({
      ...prevState,
      name: props.name,
      latLong: props.latLong,
      interestFamily: props.interestFamily,
      interestType: props.interestType,
      interestSubType: props.interestSubType,
      data: props.data,
      punteroUser: props.punteroUser,
      comments: props.comments,
      mainAddress: props.mainAddress,
      secondaryAddress: props.secondaryAddress,
      department: props.department,
      neighborhood: props.neighborhood,
      city: props.city,
      houseNumber: props.houseNumber,
      status: props.status,
      id: props.id,
      fechaDeActualizacion: formateadorSuperProDeFechas(props.updatedAt),
    }))
    getFamily()
    getGI()
    getMetaData()
    if (
      props.interestFamily?.id &&
      props.interestType?.id &&
      props.interestSubType?.id
    ) {
      setFamiliaSeleccionada(props.interestFamily?.id)
      setTipoSeleccionado(props.interestType?.id)
      setSubTipoSeleccionado(props.interestSubType?.id)
    }
  }

  // onMounted
  useEffect(() => {
    initialGlobalState()
  }, [])

  // Watchers
  useEffect(() => {
    googleApi()
  }, [data.latLong])

  useEffect(() => {
    if (
      mapClicked &&
      googleApiResponse &&
      googleApiResponse.results &&
      googleApiResponse.results.length > 0
    ) {
      const ciudadesSet = new Set()
      const barriosSet = new Set()
      const calles1Set = new Set()
      const calles2Set = new Set()
      const depaSet = new Set()

      googleApiResponse.results.forEach((result) => {
        const ciudadEncontrada =
          result.address_components.find((component) =>
            component.types.includes("locality"),
          )?.long_name || ""

        const barrioEncontrado =
          result.address_components.find((component) =>
            component.types.includes("neighborhood"),
          )?.long_name || ""

        const calle1Encontrada =
          result.address_components.find((component) =>
            component.types.includes("route"),
          )?.long_name || ""

        const depaEncontrada =
          result.address_components.find((component) =>
            component.types.includes("administrative_area_level_1"),
          )?.long_name || ""

        const calle2Encontrada =
          result.address_components
            .filter((component) => component.types.includes("route"))
            .find((component, idx, arr) => component.long_name !== arr[0]?.long_name)
            ?.long_name || ""

        if (ciudadEncontrada) ciudadesSet.add(ciudadEncontrada)
        if (barrioEncontrado) barriosSet.add(barrioEncontrado)
        if (calle1Encontrada) calles1Set.add(calle1Encontrada)
        if (calle2Encontrada) calles2Set.add(calle2Encontrada)
        if (depaEncontrada) depaSet.add(depaEncontrada)
      })

      const ciudadesArray = Array.from(ciudadesSet)
      const barriosArray = Array.from(barriosSet)
      const calles1Array = Array.from(calles1Set)
      const calles2Array = Array.from(calles2Set)
      const depaArray = Array.from(depaSet)

      setData({
        ...data,
        mainAddress: calles1Array[0],
        secondaryAddress: calles2Array[0],
        city: ciudadesArray[0],
        neighborhood: barriosArray[0],
        department: depaArray[0],
      })
    }
  }, [googleApiResponse, mapClicked])

  useEffect(() => {
    getTipo()
  }, [familiaSeleccionada])

  useEffect(() => {
    getSubTipo()
  }, [tipoSeleccionado])

  useEffect(() => {
    getMetaData()
  }, [grupoDeInformacionSeleccionado])

  // Commons
  const formateadorSuperProDeFechas = (dateString) => {
    const date = new Date(dateString)
    const day = String(date.getUTCDate()).padStart(2, "0")
    const month = String(date.getUTCMonth() + 1).padStart(2, "0")
    const year = date.getUTCFullYear()
    return `${day}/${month}/${year}`
  }

  const DraggableMarker = (markerPosition) => {
    const eventHandlers = {
      dragend(e) {
        const marker = e.target
        const { lat, lng } = marker.getLatLng()
        setData((prevState) => ({
          ...prevState,
          latLong: { lat, long: lng },
        }))
      },
    }

    return (
      <Marker
        position={markerPosition.markerPosition}
        draggable
        eventHandlers={eventHandlers}
        icon={iconPerson}
      ></Marker>
    )
  }

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng
        setData((prevState) => ({
          ...prevState,
          latLong: { lat, long: lng },
        }))
        setMapClicked(true)
      },
    })
    return null
  }

  // API calls
  const getMetaData = async () => {
    try {
      const response = await axios.get("interest/get-all-info-meta")
      if (response.status === 200) {
        setInformacionMeta({ ...informacionMeta, content: response.data })
      }
    } catch (error) {
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const getGI = async () => {
    try {
      const response = await axios.get("interest/get-all-group-of-information")
      if (response.status === 200) {
        setGrupoDeInformacion({ ...grupoDeInformacion, content: response.data })
      }
    } catch (error) {
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const getFamily = async () => {
    try {
      const response = await axios.get("interest/get-all-family-points")
      if (response.status === 200) {
        setFamilia({ ...familia, content: response.data })
      }
    } catch (error) {
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const googleApi = async () => {
    if (mapClicked) {
      try {
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.latLong.lat},${data.latLong.long}&key=AIzaSyBKFjX2JkYSKBljofVI4sELTqAJUjfGvmw`,
        ).then((response) =>
          response.json().then((data) => {
            setGoogleApiResponse(data)
          }),
        )
      } catch (error) {
        if (error.response) {
          await alertWarningError(error.response)
        }
      }
    }
  }

  const getTipo = async () => {
    if (!familiaSeleccionada) {
      return
    }
    try {
      const response = await axios.get(
        `interest/get-type-point-by-family-id/${familiaSeleccionada}`,
      )
      if (response.status === 200) {
        setTipo({ ...tipo, content: response.data })
      }
    } catch (error) {
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const getSubTipo = async () => {
    if (!tipoSeleccionado) {
      return
    }
    try {
      const response = await axios.get(
        `/interest/get-sub-type-point-by-family-id/${tipoSeleccionado}`,
      )
      if (response.status === 200) {
        setSubTipo({ ...subTipo, content: response.data })
      }
    } catch (error) {
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const manejarElHandleDelSaveDelGuardar = async () => {
    const fechaActual = new Date()
    const fechaConMilisegundos = fechaActual
      .toISOString()
      .replace("T", " ")
      .replace("Z", "")
    const milisegundos = fechaActual.getMilliseconds().toString().padStart(3, "0")
    const fechaCompleta = fechaConMilisegundos + "." + milisegundos

    setData({
      ...data,
      fechaDeActualizacion: fechaCompleta,
    })
    try {
      const response = await axios.patch(
        `interest/update-point-of-interest/${data.id}`,
        data,
      )
      if (response.status === 200) {
        await swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
      history.goBack()
    } catch (error) {
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  // For TextFields
  const cambiarNombre = (texto) => {
    setData({ ...data, name: texto.target.value })
  }
  const changeStatus = (e, avoidable) => {
    setData({ ...data, status: avoidable.status })
  }
  const cambioDeComentario = (commentary) => {
    setData({ ...data, comments: commentary.target.value })
  }

  const cambiarMainAddress = (mainAddress) => {
    setData({ ...data, mainAddress: mainAddress.target.value })
  }

  const cambiarSecondaryAddress = (secAddress) => {
    setData({ ...data, secondaryAddress: secAddress.target.value })
  }

  const cambiarDepartamento = (department) => {
    setData({ ...data, department: department.target.value })
  }

  const cambiarCity = (city) => {
    setData({ ...data, city: city.target.value })
  }

  const cambiarNeighborhood = (neighborhood) => {
    setData({ ...data, neighborhood: neighborhood.target.value })
  }

  const cambiarHouseNumber = (houseNumber) => {
    setData({ ...data, houseNumber: parseInt(houseNumber.target.value) })
  }

  // For Selectors
  const manejarFamiliaSeleccionada = (e, selectedFamily) => {
    setData({ ...data, interestFamily: selectedFamily })
    setFamiliaSeleccionada(selectedFamily.id)
  }
  const manejarTipoSeleccionado = (e, selectedType) => {
    setData({ ...data, interestType: selectedType })
    setTipoSeleccionado(selectedType.id)
  }
  const manejarSubTipoSeleccionado = (e, selectedSubType) => {
    setData({ ...data, interestSubType: selectedSubType })
    setSubTipoSeleccionado(selectedSubType.id)
  }

  const [metaData] = useState({})
  const handleUpdateData = (parentKey, key, value) => {
    let temp = metaData
    temp[parentKey] = {
      ...metaData[parentKey],
      [key]: value,
    }
    setData({ ...data, data: temp })
  }

  const restartLocationBtn = () => {
    setData((prevState) => ({
      ...prevState,
      latLong: props.latLong,
    }))
  }

  return (
    <>
      <Card>
        <CardContent>
          <Grid style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="nombre"
              name="nombre"
              label="Nombre"
              value={data.name || ""}
              onChange={(value) => cambiarNombre(value)}
              type="text"
              fullWidth
            />
          </Grid>

          <Grid item sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
            <Autocomplete
              id="Estado"
              size="small"
              value={status.content.find((obj) => obj.status === data.status) || ""}
              onChange={changeStatus}
              options={status?.content}
              getOptionLabel={(option) => option.status ?? ""}
              renderOption={(option) => (
                <React.Fragment>{option?.status}</React.Fragment>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Estado"
                  name="estado"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
            <Autocomplete
              id="Familia"
              size="small"
              value={props.interestFamily ?? data.interestFamily}
              onChange={manejarFamiliaSeleccionada}
              options={familia?.content}
              getOptionLabel={(option) => option.name ?? ""}
              renderOption={(option) => (
                <React.Fragment>{option?.name}</React.Fragment>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Familia"
                  name="familia"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
            <Autocomplete
              id="Tipo"
              size="small"
              value={props.interestType ?? data.interestType}
              onChange={manejarTipoSeleccionado}
              options={tipo?.content}
              getOptionLabel={(option) => option.subCategory ?? ""}
              renderOption={(option) => (
                <React.Fragment>{option?.subCategory}</React.Fragment>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Tipo"
                  name="tipo"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 10 }}>
            <Autocomplete
              id="SubTipo"
              size="small"
              value={props.interestSubType ?? data.interestSubType}
              onChange={manejarSubTipoSeleccionado}
              options={subTipo?.content}
              getOptionLabel={(option) => option.serviceTarget ?? ""}
              renderOption={(option) => (
                <React.Fragment>{option?.serviceTarget}</React.Fragment>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="SubTipo"
                  name="subtipo"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>

          <Grid style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="fechaActualizacion"
              name="Fecha de Actualizacion"
              label="Fecha de Actualizacion"
              value={data.fechaDeActualizacion || ""}
              type="text"
              fullWidth
              disabled
            />
          </Grid>

          <Grid style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="Comentarios"
              name="Comentarios"
              label="Comentarios"
              value={data.comments || ""}
              onChange={(valor) => cambioDeComentario(valor)}
              type="text"
              fullWidth
            />
          </Grid>

          {data.latLong && data.latLong?.lat && data.latLong?.long ? (
            <>
              <div style={{ marginBottom: 10 }}>
                <BotonGris
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={restartLocationBtn}
                >
                  Reiniciar mapa
                </BotonGris>
              </div>
              <MapContainer
                center={[data.latLong?.lat, data.latLong?.long]}
                zoom={16}
                style={{ height: "300px", width: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <DraggableMarker
                  markerPosition={[data.latLong?.lat, data.latLong?.long]}
                />
                <MapClickHandler></MapClickHandler>
              </MapContainer>
            </>
          ) : (
            <></>
          )}

          <Grid style={{ marginTop: 30, marginBottom: 15 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="mainAddress"
              name="Calle Principal"
              label="Calle Principal"
              value={data.mainAddress || ""}
              onChange={(value) => cambiarMainAddress(value)}
              type="text"
              fullWidth
            />
          </Grid>

          <Grid style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="mainAddress"
              name="Calle Secundaria"
              label="Calle Secundaria"
              value={data.secondaryAddress || ""}
              onChange={(value) => cambiarSecondaryAddress(value)}
              type="text"
              fullWidth
            />
          </Grid>

          <Grid style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="department"
              name="Departamento"
              label="Departamento"
              value={data.department || ""}
              onChange={(value) => cambiarDepartamento(value)}
              type="text"
              fullWidth
            />
          </Grid>

          <Grid style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="city"
              name="Ciudad"
              label="Ciudad"
              value={data.city || ""}
              onChange={(value) => cambiarCity(value)}
              type="text"
              fullWidth
            />
          </Grid>

          <Grid style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="neighborhood"
              name="Barrio"
              label="Barrio"
              value={data.neighborhood || ""}
              onChange={(value) => cambiarNeighborhood(value)}
              type="text"
              fullWidth
            />
          </Grid>

          <Grid style={{ marginBottom: 10 }}>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              id="housenumber"
              name="Número de Casa"
              label="Número de Casa"
              value={data.houseNumber || ""}
              onChange={(value) => cambiarHouseNumber(value)}
              type="text"
              fullWidth
            />
          </Grid>
          {Object.keys(props.data).length === 0
            ? grupoDeInformacion.content.map((item, index) => {
                return (
                  <div>
                    <div>
                      <Grid style={{ marginBottom: 10 }}>
                        <Typography
                          id={index}
                          name={item.type}
                          label={item.type}
                          value={item.type}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          {item.type}
                        </Typography>
                      </Grid>
                    </div>
                    <div>
                      {informacionMeta.content.map((itemChild, indexChild) => {
                        return (
                          <div>
                            {itemChild.groupInfo.id === item.id ? (
                              itemChild.type === "TEXTFIELD" ||
                              itemChild.type === "TEXTAREA" ? (
                                <TextField
                                  style={{ marginBottom: 10 }}
                                  name={itemChild.label}
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  label={itemChild.label}
                                  id={indexChild}
                                  onChange={(value) =>
                                    handleUpdateData(
                                      item.type,
                                      itemChild.label,
                                      value.target.value,
                                    )
                                  }
                                ></TextField>
                              ) : itemChild.type === "SELECT" ? (
                                <Grid
                                  item
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  style={{ marginBottom: 10 }}
                                >
                                  <Autocomplete
                                    id={indexChild}
                                    size="small"
                                    onChange={(value) => {
                                      handleUpdateData(
                                        item.type,
                                        itemChild.label,
                                        value.target.innerText,
                                      )
                                    }}
                                    options={JSON.parse(itemChild.value)}
                                    getOptionLabel={(option) => option ?? ""}
                                    renderOption={(option) => (
                                      <React.Fragment>{option}</React.Fragment>
                                    )}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id={indexChild}
                                        variant="outlined"
                                        label={itemChild.label}
                                        name={itemChild.label}
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })
            : grupoDeInformacion.content.map((item, index) => {
                return (
                  <div>
                    <div>
                      <Grid style={{ marginBottom: 10 }}>
                        <Typography
                          id={index}
                          name={item.type}
                          label={item.type}
                          value={item.type}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          {item.type}
                        </Typography>
                      </Grid>
                    </div>
                    <div>
                      {informacionMeta.content.map((itemChild, indexChild) => {
                        return (
                          <div>
                            {itemChild.groupInfo.id === item.id ? (
                              itemChild.type === "TEXTFIELD" ||
                              itemChild.type === "TEXTAREA" ? (
                                <TextField
                                  style={{ marginBottom: 10 }}
                                  name={itemChild.label}
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  label={itemChild.label}
                                  id={indexChild}
                                  value={
                                    Object.keys(props.data).length === 0
                                      ? ""
                                      : data.data[item.type][itemChild.label] || ""
                                  }
                                  onChange={(value) =>
                                    handleUpdateData(
                                      item.type,
                                      itemChild.label,
                                      value.target.value,
                                    )
                                  }
                                ></TextField>
                              ) : itemChild.type === "SELECT" ? (
                                <Grid
                                  item
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  style={{ marginBottom: 10 }}
                                >
                                  <Autocomplete
                                    id={indexChild}
                                    size="small"
                                    value={
                                      Object.keys(props.data).length === 0
                                        ? ""
                                        : data.data[item.type][itemChild.label] || ""
                                    }
                                    onChange={(value) => {
                                      handleUpdateData(
                                        item.type,
                                        itemChild.label,
                                        value.target.innerText,
                                      )
                                    }}
                                    options={JSON.parse(itemChild.value)}
                                    getOptionLabel={(option) => option ?? ""}
                                    renderOption={(option) => (
                                      <React.Fragment>{option}</React.Fragment>
                                    )}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id={indexChild}
                                        variant="outlined"
                                        label={itemChild.label}
                                        name={itemChild.label}
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}

          <Grid item>
            <BotonVerde
              variant="contained"
              size="small"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={manejarElHandleDelSaveDelGuardar}
            >
              Guardar
            </BotonVerde>{" "}
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
