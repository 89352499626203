import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import Divider from "@material-ui/core/Divider"
import { useStyles } from "../assets/styles/CustomStyles"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Paper from "@material-ui/core/Paper"
import { Typography } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { withStyles } from "@material-ui/core/styles"
import axios from "../utils/axios"

import { alertWarningError } from "./Notificaciones"
import LinearProgress from "@material-ui/core/LinearProgress"

BuscadorDirectorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSetData: PropTypes.func.isRequired,
  idUser: PropTypes.number.isRequired,
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#fff",
  },
})
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const defaultValue = {
  content: [],
  totalElementos: 0,
}
export default function BuscadorDirectorDialog(props) {
  /**
   * @param {boolean} open muestra el dialogo en pantalla
   * @param {boolean} onClose muestra el dialogo en pantalla
   * @param {object} onSetData usamos si el dialogo tiene formulario, por este parametro le enviamos al padre los datos insertado
   */
  const { open, onClose, onSetData } = props
  const [director, setDirector] = useState(defaultValue)
  const [textSearch, setTextSearch] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    onsubmitFiltro()
  }, [])

  const handleOnSetData = (props) => {
    onSetData(props)
    setDirector(defaultValue)
  }

  const onsubmitFiltro = async (props) => {
    try {
      setIsLoading(true)

      const response = await axios.post(`colaboradores/colaborador-listar/`, {
        idColaborador: null,
      })
      let status = response.status
      if (status === 200) {
        let data = response.data
        setDirector({
          ...director,
          content: data?.result,
          totalElementos: data?.result?.length,
        })
        setTextSearch("")
      }
    } catch (error) {
      onClose()
      if (error.response) {
        alertWarningError(error.response)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const Director = () => {
    return (
      <>
        <DialogTitle
          id="form-dialog-diector"
          className={classes.DialogTitle}
          onClose={onClose}
        >
          <Typography variant="body1" display="block">
            SELECCIONAR RESPONSABLE
          </Typography>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
            >
              {/*
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
         
                  <Paper className={classes.rootPaper}>
                    <InputBase
                      className={classes.input}
                      id="buscador"
                      name="buscador"
                      placeholder="BUSCADOR"
                      fullWidth
                      autoFocus
                      onChange={(e) => handleChange(e)}
                    value={
                      textSearch && textSearch
                      
                    }
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    
                      <IconButton
                        style={{
                          color: "#396b99",
                          textAlign: "center",
                          minWidth: 45,
                          padding: "1px 8px 1px 8px",
                        }}
                        className={classes.iconButton}
                        aria-label="search"
                    onClick={() => onsubmitFiltro()}
                      >
                        <Search />
                      </IconButton>
                    
                  </Paper>
               
        
              </Grid>
              /*}
            </Grid>
            {/** lista de articulos */}
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
              >
                <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider className={classes.DialogoTexto} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.tableHead}
                            align="justify"
                            width={"15%"}
                          >
                            CODIGO
                          </TableCell>
                          <TableCell
                            className={classes.tableHead}
                            align="justify"
                            width={"65%"}
                          >
                            NOMBRE COMPLETO
                          </TableCell>
                          <TableCell
                            className={classes.tableHead}
                            align="justify"
                            width={"20%"}
                          >
                            TIPO USUARIO
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {director.content?.length > 0 ? (
                          director.content.map((lista) => {
                            return (
                              <TableRow
                                key={lista.id}
                                onClick={() => {
                                  handleOnSetData(lista)
                                  onClose()
                                }}
                              >
                                <TableCell align="justify">{lista.id}</TableCell>
                                <TableCell align="justify">
                                  {lista.nombre + " " + lista.apellido}
                                </TableCell>
                                <TableCell align="justify">
                                  {lista.tipoUsuario}
                                </TableCell>
                              </TableRow>
                            )
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={12}>
                              <Typography align="center" variant="body2">
                                No se encontraron registros para mostrar
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <Divider />
      </>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <Director />

      {isLoading && <LinearProgress />}
    </Dialog>
  )
}
