import axios from "axios"
import { logout, TOKEN_KEY } from "./user/UserService"
import SweetAlert from "sweetalert2"

//import { refreshToken } from './authUtils';
//import history from './history';

export const origin = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`

export const CancelToken = axios.CancelToken

const instance = axios.create({
  baseURL: origin,
  //timeout: 10000,
  headers: { "Content-Type": "application/json" },
})

let tokenCookies = window.localStorage.getItem(TOKEN_KEY)

instance.interceptors.request.use(
  (config) => {
    let token = `Bearer ${JSON.parse(tokenCookies)}`
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

//intercerptor para los errores 401 y 403
instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          SweetAlert.fire({
            icon: "warning",
            title: "¡ATENCIÓN!",
            text: error.response.data.message,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#43a047",
          })
          return

        case 401:
          SweetAlert.fire({
            icon: "warning",
            title: "¡SESIÓN EXPIRADA!",
            text: "Por favor vuelva a logearse.",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#43a047",
          }).then(function () {
            logout()
          })

          return

        case 403:
          SweetAlert.fire({
            icon: "warning",
            title: "¡ATENCIÓN!",
            text: "Usted no cuenta con permiso para acceder a esta página.",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#43a047",
          }).then(function () {
            window.history.back()
          })

          return

        case 404:
          SweetAlert.fire({
            icon: "warning",
            title: "¡ATENCIÓN!",
            text: "La página no se encuentra disponible por el momento, vuelva a intentarlo en unos minutos.",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#43a047",
          })

          return

        case 500:
          SweetAlert.fire({
            icon: "error",
            title: "¡ATENCIÓN!",
            text: "Error en el servidor, vuelva a intentarlo en unos minutos.",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#43a047",
          })

          return

        case 503:
          SweetAlert.fire({
            icon: "error",
            title: "¡ATENCIÓN!",
            text: "El servicio no se encuentra disponible, vuelva a intentarlo en unos minutos.",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#43a047",
          })

          return

        default:
          SweetAlert.fire({
            icon: "error",
            title: "OCURRIO UN ERROR!",
            text: "Ocurrio un error inesperado, vuelva a intentarlo o contacte con soporte técnico.",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#43a047",
          })
          break
      }
    }

    return Promise.reject(error)
  },
)

export default instance
