import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography } from "@material-ui/core"
import { alertWarningError } from "../../components/Notificaciones"
import Chip from "@material-ui/core/Chip"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import VisibilityIcon from "@material-ui/icons/Visibility"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import EditIcon from "@material-ui/icons/Edit"
import Check from "@material-ui/icons/Check"
import { render } from "@testing-library/react"

export default function ListaComercioActivar() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })

  useEffect(() => {
    getComercios()
  }, [])

  const getComercios = async (props) => {
    setIsLoading(true)
    let url = "commerce/get-comerces-status"
    try {
      const response = await axios.get(url, {
        params: { limit: 5, page: 1, status: "ACTIVATE" },
      })
      let status = response.status
      if (status === 200) {
        const clientes = response.data
        setData({ ...data, content: clientes })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nuevo-comercio")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "id",
      width: "1%",
      hidden: true,
    },
    {
      title: "",
      align: "center",
      render: (rowData) =>
        rowData.mainPicture ? (
          <img
            style={{ height: 40, borderRadius: "20%" }}
            src={rowData.mainPicture?.url}
            alt="Pendiente de Recarga"
          />
        ) : (
          <></>
        ),
      width: "10%",
    },

    {
      title: "Nombre",
      field: "name",
      // width: "10%",
    },
    {
      title: "Razón Social",
      field: "razon_social",
      render: (rowData) => <p>{rowData.ruc?.ruc || ""}</p>,
    },
    {
      title: "RUC",
      field: "ruc",
      width: "12%",
      render: (rowData) => <p>{rowData.ruc?.socialReason || ""}</p>,
    },
    {
      title: "Ciudad",
      field: "ciudad",
      width: "10%",
    },

    {
      title: "Contacto",
      field: "nro_contacto",
      width: "10%",
    },
  ]
  const options = {
    filtering: false,
    exportButton: true,
    exportAllData: true,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const editar = (event, props) => {
    event.stopPropagation()
    // handleOpenEditar(props.data);
  }

  const handleDetalle = (event, props) => {
    event.stopPropagation()
    history.push("./detalle/", props.data)
  }
  const handleInactivar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres pasar para activar  ${props.data?.name}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        inactivar(props.data)
      }
    })
  }

  const inactivar = async (props) => {
    console.log(props)

    setIsLoading(true)
    let url = "commerce/update"
    try {
      const response = await axios.patch(
        url,
        { ...props, formStatus: "MAINTENANCE" },
        { params: { commerceId: props.id } },
      )
      let status = response.status
      if (status === 200) {
        getComercios()
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleInactivar(e, props)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <Check fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Pasar en Mantenimiento"
            variant="outlined"
            color="error"
          />
        </Box>

        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleDetalle(e, props)}
            avatar={
              <Avatar variant="circle">
                <VisibilityIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Detalle"
            variant="outlined"
            color="primary"
          />
        </Box>

        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => history.push("./editar/", props.data)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Editar"
            variant="outlined"
            color="secondary"
          />
        </Box>
      </>
    )
  }
  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              //  editar,
              childrenAccions,
            }}
          />
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
