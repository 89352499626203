import React, { useState, useEffect } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import {
  TextField,
  Grid,
  Button,
  Avatar,
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import swal from "sweetalert"
import BackdropCustom from "../../components/BackdropCustom"
import Autocomplete from "@material-ui/lab/Autocomplete"

// const tipoFamiliaList = {
//     content: [
//         { id: 1, nombre: "Peluqueria" },
//         { id: 2, nombre: "Despensa" },
//         { id: 3, nombre: "Carniceria'" },
//     ],
// };

const inicialValue = {
  nombre: "",
  descripcion: "",
  estado: "ACEPTADO",
}

export default function NuevoTipo() {
  const history = useHistory()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [tipoPedido, setTipoPedido] = useState(inicialValue)
  const [selectedFamily, setSelectedFamily] = useState(null)
  const [isLoadingTipoUsuario, setIsLoadingTipoUsuario] = useState(false)
  const [tipoFamiliaList, setTipoFamiliaList] = useState([])

  const crearTipo = async () => {
    setIsLoading(true)
    const url = "interest/create-point-of-interest-type"

    try {
      if (!selectedFamily) {
        setIsLoading(false)
        return
      }
      const response = await axios.post(url, {
        subCategory: tipoPedido.nombre,
        status: tipoPedido.estado,
        interestFamily: selectedFamily,
      })
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        await swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getTipoFamilia = async () => {
    const url = "interest/get-all-family-points"
    try {
      const responseFamily = await axios.get(url)
      const responseStatus = responseFamily.status
      if (responseStatus === 200) {
        setTipoFamiliaList(responseFamily.data)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  useEffect(() => {
    getTipoFamilia()
  }, [])

  const handleChangeNombre = (event) => {
    let copyInput = { ...tipoPedido, nombre: event.target.value }
    setTipoPedido(copyInput)
  }

  const onSelectTipoUsuario = (event, value) => {
    setSelectedFamily(value)
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="nombre"
                name="Nombre"
                label="Nombre"
                value={tipoPedido.nombre}
                onChange={(value) => handleChangeNombre(value)}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Autocomplete
                id="tipoFamilia"
                size="small"
                value={selectedFamily || ""}
                onChange={onSelectTipoUsuario}
                options={tipoFamiliaList}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderOption={(option) => (
                  <React.Fragment>{option?.name}</React.Fragment>
                )}
                loading={isLoadingTipoUsuario}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tipo de Familia"
                    name="tipoFamilia"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingTipoUsuario ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  crearTipo()
                }}
              >
                Guardar
              </BotonVerde>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
