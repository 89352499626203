import React, { useEffect, useState, useContext } from "react"
import { MakeTables } from "../../components/MaterialTables/MakeTables"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import { Typography } from "@material-ui/core"
import { alertWarningError } from "../../components/Notificaciones"
import Chip from "@material-ui/core/Chip"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import AccesoDenegado from "../../components/AccesoDenegado"
import UserContext from "../../utils/user/UserContext"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircle"
import VisibilityIcon from "@material-ui/icons/Visibility"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import Alert from "@material-ui/lab/Alert"
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import { pink, lightGreen } from "@material-ui/core/colors"
import swal from "sweetalert"
import EditIcon from "@material-ui/icons/Edit"
import TodayIcon from "@material-ui/icons/Today"

export default function ListaBanner() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ content: [] })
  const [state, setState] = useState({})

  useEffect(() => {
    getData()
  }, [])

  const getData = async (props) => {
    setIsLoading(true)
    let url = "banner/banner-listar"
    try {
      const response = await axios.post(url)
      let status = response.status
      if (status === 200) {
        const responseData = response.data
        setData({ ...data, content: responseData?.result })

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const title = (
    <Button
      size="small"
      variant="outlined"
      style={{ color: pink[600] }}
      startIcon={<AddIcon />}
      onClick={() => history.push("../nuevo-banners")}
    >
      Agregar
    </Button>
  )

  const columns = [
    {
      title: "ID",
      field: "idBanner",
      width: "1%",
      hidden: true,
    },
    {
      title: "",
      field: "urlFoto",
      align: "center",
      render: (rowData) => (
        <img
          style={{ height: 80, borderRadius: "4%" }}
          src={`${rowData?.urlFoto}`}
          alt="Pendiente de Recarga"

          // src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}${rowData?.url}`}
        />
      ),
      width: "10%",
    },

    {
      title: "Descripción",
      field: "descripcion",
      // width: "10%",
    },

    {
      title: "Fecha Desde",
      field: "fechaDesde",
      width: "10%",
      render: (rowData) => {
        let fecha = rowData.fechaDesde?.split("T")
        return (
          <Chip
            icon={<TodayIcon />}
            color="default"
            label={fecha?.length > 0 ? fecha[0] : "Sin Fecha"}
            size="small"
          />
        )
      },
    },

    {
      title: "Fecha Hasta",
      field: "fechaHasta",
      width: "10%",
      render: (rowData) => {
        let fecha = rowData.fechaHasta?.split("T")
        return (
          <Chip
            icon={<TodayIcon />}
            color="default"
            label={fecha?.length > 0 ? fecha[0] : "Sin Fecha"}
            size="small"
          />
        )
      },
    },

    {
      title: "Orden",
      field: "orden",
      width: "8%",
      align: "center",
      render: (rowData) => (
        <Alert icon={false} variant="outlined" severity="warning">
          {rowData?.orden}
        </Alert>
      ),
    },
  ]
  const options = {
    filtering: false,
    exportButton: true,
    exportAllData: true,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "65vh",
    paging: true,
    // searchFieldAlignment:"left",
    //    showTitle:false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  }

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ]

  const handleInactivar = (event, props) => {
    event.stopPropagation()
    swal({
      title: "¡ATENCIÓN!",
      text: `¿Estás seguro que quieres inactivar  ${props.data?.descripcion}?`,
      icon: "warning",
      // buttons: true,
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
      confirmButtonColor: "#EE273E",
    }).then((willDelete) => {
      if (willDelete) {
        inactivar(props.data)
      }
    })
  }

  const inactivar = async (props) => {
    setIsLoading(true)
    let url = "banner/banner-inactivar"
    try {
      const response = await axios.post(url, {
        idBanner: props?.idBanner,
      })
      let status = response.status
      if (status === 200) {
        getData()
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDetalle = (event, props) => {
    event.stopPropagation()
    history.push("./detalle/", props.data)
  }

  const childrenAccions = (props) => {
    return (
      <>
        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => handleInactivar(e, props)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <HighlightOffIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Inactivar"
            variant="outlined"
            color="error"
          />
        </Box>

        <Box pl={1} pr={1}>
          <Chip
            onClick={(e) => history.push("./editar/", props.data)}
            avatar={
              <Avatar variant="circle" sx={{ bgcolor: "red" }}>
                <EditIcon fontSize="small" style={{ color: "#ffffff" }} />
              </Avatar>
            }
            label="Editar"
            variant="outlined"
            color="secondary"
          />
        </Box>
      </>
    )
  }
  return (
    <>
      {userContext.state.nombreUsu !== "" ? (
        <>
          <MakeTables
            isLoading={isLoading}
            title={title}
            columns={columns}
            data={data.content}
            actions={actions}
            classes={classes}
            options={options}
            componentsAssets={{
              classes,
              //  editar,
              childrenAccions,
            }}
          />
        </>
      ) : (
        <AccesoDenegado />
      )}
    </>
  )
}
