import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import UserContext from "../../utils/user/UserContext"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import swal from "sweetalert"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import Badge from "@material-ui/core/Badge"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"

import BackdropCustom from "../../components/BackdropCustom"

const inicialValue = {
  nombre: "",
}

export default function NuevaCategoria() {
  const history = useHistory()
  const classes = useStyles()
  const userContext = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [categoria, setCategoria] = useState(inicialValue)
  const [fotoPerfil, setFotoPerfil] = useState({})

  useEffect(() => {}, [])

  const uploaderProps = {
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    //carga la imagen seleccionada
    onProgress(step, file) {
      //creamos un objeto para leer luego el archivo "file"
      const reader = new FileReader()

      const duplicado = fotoPerfil?.file?.uid === file.uid

      if (!duplicado) {
        //le pasamos el file para leer el contenido del Blob, esto retorna un "reader.result" que le cargamos en el campo "byteImages"
        reader.readAsDataURL(file)
        //entra aca si la operacion de lectura del archivo fue satisfactoria
        reader.onload = function (event) {
          let base64 = reader.result.split(";base64,")
          let documentoBase64 = base64[1]
          //creamos el objeto para cargar los valores
          let imagen = {
            file: file,
            type: file.type,
            name: file.name,
            data: documentoBase64,
          }

          setFotoPerfil(imagen)
        }

        //ocurre un error a la hora de leer el archivo
        reader.onerror = function () {
          alert("couldn't read the file")
        }
      }
    },

    //ocurre un error a la hora de subir el archivo
    onError(err) {
      alert("onError", err)
    },
    capture: "josue",
  }

  const handleGuardar = async () => {
    setIsLoading(true)
    let url = "configuraciones/categoria-crear"

    try {
      const response = await axios.post(url, categoria)
      let status = response.status
      if (status === 200) {
        if (Object.keys(fotoPerfil).length === 0) {
          setIsLoading(false)
          swal("¡OPERACIÓN EXITOSA!", {
            icon: "success",
            buttons: false,
            timer: 1500,
          })
          history.goBack()
        } else {
          handleEnviarFotoPerfil(response.data?.result?.idCategoria)
        }
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }
  const handleEnviarFotoPerfil = async (props) => {
    const formData = new FormData()
    formData.append("imgs", fotoPerfil?.file)
    try {
      const response = await axios.post(
        `configuraciones/foto-categoria/${props}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
      let status = response.status
      if (status === 201) {
        setIsLoading(false)
        swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...categoria, nombre: event.target.value }
    setCategoria(copyInput)
  }

  const handleEliminarImagen = (event) => {
    event.preventDefault()
    setFotoPerfil({})
  }

  return (
    <>
      <BackdropCustom open={isLoading} />
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              {Object.keys(fotoPerfil).length === 0 ? (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Avatar alt="Foto categoría" style={{ width: 100, height: 100 }}>
                    <PhotoCameraIcon fontSize="large" />
                  </Avatar>
                </Box>
              ) : (
                <Box mb={2} style={{ textAlign: "-webkit-center" }}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Tooltip title="Eliminar imagen" aria-label="eleminar">
                        <IconButton
                          edge="end"
                          variant="contained"
                          aria-label="delete"
                          onClick={(event) => handleEliminarImagen(event)}
                        >
                          <DeleteIcon style={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Avatar
                      alt="Foto categoría"
                      src={`data:${fotoPerfil?.type};base64,${fotoPerfil?.data}`}
                      style={{ width: 100, height: 100 }}
                    />
                  </Badge>
                </Box>
              )}

              <Upload {...uploaderProps}>
                <Button
                  size="small"
                  //  startIcon={<PhotoCameraIcon />}
                  //startIcon={PublishIcon}
                  variant="contained"
                  color="primary"
                  children="Foto categoría  "
                />
              </Upload>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                spacing={2}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    size="small"
                    autoFocus
                    variant="outlined"
                    id="descripcion"
                    name="nombre"
                    label="Nombre"
                    value={categoria.nombre}
                    onChange={(value) => handleChangeNombre(value)}
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleGuardar()
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
