import React, { useState, useEffect, useContext } from "react"
import { useStyles } from "../../assets/styles/CustomStyles"
import { useHistory } from "react-router-dom"

import axios from "../../utils/axios"
import TextField from "@material-ui/core/TextField"
import { Grid, Button, Avatar, Box } from "@material-ui/core"
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Upload from "rc-upload"
import { alertWarningError } from "../../components/Notificaciones"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import SaveIcon from "@material-ui/icons/Save"
import swal from "sweetalert"
import BackdropCustom from "../../components/BackdropCustom"

const inicialValue = {
  nombre: "",
}

export default function EditarTipoGrupoDeInformacion() {
  const history = useHistory()
  const props = history.location.state
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [tipoInfo, setTipoInfo] = useState(inicialValue)

  const setInitialState = () => {
    setTipoInfo((prevState) => ({
      ...prevState,
      nombre: props.type,
    }))
  }

  useEffect(() => {
    setInitialState()
  }, [])

  const updateGroupOfInfo = async (groupId) => {
    setIsLoading(true)
    if (!tipoInfo.nombre) {
      setIsLoading(false)
      return
    }
    const url = `/interest/update-group-info/${groupId}?newName=${tipoInfo.nombre}`

    try {
      const response = await axios.patch(url)
      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        await swal("¡OPERACIÓN EXITOSA!", {
          icon: "success",
          buttons: false,
          timer: 1500,
        })
        history.goBack()
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        await alertWarningError(error.response)
      }
    }
  }

  const handleChangeNombre = (event) => {
    let copyInput = { ...tipoInfo, nombre: event.target.value }
    setTipoInfo(copyInput)
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            spacing={2}
          >
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <TextField
                size="small"
                autoFocus
                variant="outlined"
                id="nombre"
                name="Nombre"
                label="Nombre"
                value={tipoInfo.nombre}
                onChange={(value) => handleChangeNombre(value)}
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item>
              <BotonGris
                size="small"
                color="default"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.goBack()
                }}
              >
                Salir
              </BotonGris>{" "}
            </Grid>

            <Grid item>
              <BotonVerde
                variant="contained"
                size="small"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  updateGroupOfInfo(props.id)
                }}
              >
                Guardar
              </BotonVerde>{" "}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}
